import {ChakraProvider, ColorModeScript, CSSReset} from "@chakra-ui/react";
import * as React from "react";
import {QueryClientProvider} from "react-query";
import "react-widgets/styles.css";
import "./i18n.js";
import {queryClient} from "./libs/react-query";
import Plugins from "./plugins";
import Routes from "./routes";
import {theme} from "./theme";
import "./App.css"
export default function App() {
  return (
    <>
      <CSSReset />
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <QueryClientProvider client={queryClient}>
          <Plugins>
            <Routes />
          </Plugins>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}
