import React from "react";
import {Container, Flex, Image} from "@chakra-ui/react";
import {toAbsoluteUrl} from "../utils/helper";

export default function NavBottom() {
  return (
    <Flex mt={{ base: "40px", md: "70px", lg: "100px" }} bottom="0" backgroundColor="#DA232B" boxShadow="base" justifyContent="space-around">
      <Container maxW="1920px" align="center">
        <Image src={toAbsoluteUrl("/assets/romduol-navbottom.png")} alt="romduol-navbottom" width="100%" />
      </Container>
    </Flex>
  );
}
