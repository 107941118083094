import { API_URL } from "../../constants/app";
import { convertDateTimeToUrlFormat } from "../helper";
import { headersJson } from "./requestHeaders";

export async function getTargetDate() {
  return await fetch(`${API_URL}/wining-ticket/schedule-list`, {
    method: "POST",
    headers: headersJson(),
  })
    .then((res) => res.json())
    .then((data) => {
      const val = data.body.data[0];
      const modifiedData = {
        timeLiveTable: val.winingSchedule || null,
        timeLastTable: val.lastWiningSchedule || null,
        timePreviousTable: val.lastLastWiningSchedule || null,
      };
      return modifiedData;
    })
    .catch((error) => console.log(error));
}
// fetch history data
export async function getHistoryResult({ queryKey, pageParam = 1 }) {
  let payload = queryKey[1];
  payload = { page: pageParam, ...payload };
  return await fetch(`${API_URL}/wining-ticket/history-result-list`, {
    method: "POST",
    headers: headersJson(),
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      const modifiedData = [];
      data.body?.data?.forEach(val => {
        const formattedDataResponse = val.winingTypeResponses?.map(data => ({
          id: data.id,
          digitNumber: data.digitNumber,
          totalSwingTime: data.totalSwingTime,
          winningLevel: data.winingLevel,
          winningName: data.winingName,
          winningPrice: data.winingPrice,
          winningResult: data.winingResult
        }));

        modifiedData.push({
          dataResponse: formattedDataResponse,
          scheduleTime: val.scheduleDateTime
        });
      });
      return { modifiedData, pagination: data.body.pagination };
    })
    .catch((error) => console.log(error));
}

export async function getResult(payload) {
  const param = convertDateTimeToUrlFormat(payload);
  return await fetch(`${API_URL}/wining-ticket/result-list?winingSchedule=${param}`, {
    method: "POST",
    headers: headersJson(),
  })
    .then((res) => res.json())
    .then((data) => {
      let modifiedData = [];
      data.body?.data?.forEach((val) => {
        modifiedData.push({
          id: val.id,
          digitNumber: val.digitNumber,
          totalSwingTime: val.totalSwingTime,
          winningLevel: val.winingLevel,
          winningName: val.winingName,
          winningPrice: val.winingPrice,
          winningResult: val.winingResult,
        });
      });
      return modifiedData;
    })
    .catch((error) => console.log(error));
}
