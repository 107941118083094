import {Box, Center, Divider, Fade, Flex, Grid, HStack, Heading, IconButton, Image, Link, Menu, MenuItem, MenuList, Skeleton, SkeletonCircle, Spacer, Stack, Text, useColorModeValue as mode, useMenuButton} from "@chakra-ui/react";
import React, {createContext, useContext, useEffect, useState} from "react";
import {BiUserCircle, FaGlobe, HiOutlineMenuAlt1, HiSelector} from "react-icons/all";
import {useHistory, useLocation} from "react-router-dom";
import {APP_VERSION} from "../constants/app";
import {MenuConstants} from "../constants/menu.jsx";
import {useAuth} from "../plugins/CookieAuthPlugin";
import {checkIsActive, getCurrentUrl, toAbsoluteUrl} from "../utils/helper";

const SidebarContext = createContext("SidebarContext");

export default function Sidebar() {
  const history = useHistory();
  const location = useLocation();
  const {user, logout} = useAuth();
  const [toggle, setToggle] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const getMenuItemActive = (url) => {
    if (location.key && location.pathname !== "/") {
      return checkIsActive(location, url);
    }
  };
  useEffect(() => {
    setTimeout(
      () => {
        setCollapse(toggle);
      },
      toggle ? 0 : 80,
    );
  }, [toggle]);
  return (
    <SidebarContext.Provider value={{user, logout, collapse}}>
      <Flex zIndex="overlay" w="full" h="full" direction="column" borderRightWidth="1px" transition="width 0.1s" width={`${toggle ? "75px" : "72"}`}>
        <Flex overflow="hidden" w="full" h="full" direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
          <Box h="3rem" pos="relative" w="full" mb="50px">
            <Fade in={!collapse}>
              <Center h="6rem">
                <Image hidden={toggle} h="full" src={toAbsoluteUrl("/assets/Wingo Logo.png")} />
              </Center>
            </Fade>
            <IconButton
              _focus={{outline: "none"}}
              variant="ghost"
              colorScheme="brand"
              pos="absolute"
              top="0"
              _hover={{
                bg: "brand.500",
                color: "white",
              }}
              left="0"
              onClick={() => setToggle(!toggle)}
              icon={<HiOutlineMenuAlt1 size="1.5rem" />}
            />
          </Box>
          <Grid>
            <Heading hidden={toggle} textAlign="center" letterSpacing="wide" whiteSpace="nowrap" color="brand.100" fontWeight="semibold" fontSize="1rem">
              WINGO LOTTO
              <Box fontSize="xs" mt="7px" bg={"transparent"} color="transparent" _hover={{color: "brand.100"}}>
                v{APP_VERSION}
              </Box>{" "}
            </Heading>
          </Grid>

          <Stack spacing="2" as="nav" aria-label="Sidebar Navigation">
            {MenuConstants.map(({name, item}, i) => (
              <React.Fragment key={i}>
                <Box whiteSpace="nowrap" px="3" fontSize="xs" fontWeight="semibold" textTransform="uppercase" letterSpacing="widest" color="gray.500">
                  <Fade in={!collapse}>{name}</Fade>
                </Box>
                <Stack>
                  {item.map(({id, name, icon, path}) => (
                    <NavLink
                      key={id}
                      collapse={collapse}
                      isActive={getMenuItemActive(path)}
                      onClick={() => {
                        path !== getCurrentUrl(location) && history.push(path);
                      }}
                      icon={icon}
                      label={name}
                    />
                  ))}
                </Stack>
                {MenuConstants.length - 1 > i && <Divider bg="gray.100" rounded="md" />}
              </React.Fragment>
            ))}
          </Stack>
          <Spacer />
        </Flex>
        <Box px="5" mb="4">
          <NavLink
            collapse={collapse}
            onClick={() => {
              getCurrentUrl(location) && history.push("/");
            }}
            icon={<FaGlobe />}
            bg="gray.200"
            label="Go to Website"
          />
        </Box>
        <UserProfile />
      </Flex>
    </SidebarContext.Provider>
  );
}
const NavLink = (props) => {
  const {icon, isActive, label, collapse, ...rest} = props;
  return (
    <Link
      display="block"
      py="2"
      px="3"
      width="full"
      minW="max-content"
      borderRadius="md"
      transition="all 0.1s"
      fontWeight="medium"
      userSelect="none"
      aria-current={isActive ? "page" : undefined}
      color="gray.600"
      _hover={{
        bg: "brand.500",
        color: "white",
      }}
      _activeLink={{
        bg: "brand.500",
        color: "white",
      }}
      {...rest}
    >
      <HStack h="20px" spacing="5">
        <Box fontSize="lg">{icon}</Box>
        {!collapse && (
          <Text as="span">
            <Fade in={!collapse}>{label}</Fade>
          </Text>
        )}
      </HStack>
    </Link>
  );
};

const UserProfile = () => {
  const history = useHistory();
  const {collapse, logout} = useContext(SidebarContext);
  return (
    <HStack spacing="4" px="2" flexShrink={0} borderTopWidth="1px" p="4">
      <Menu>
        <AccountSwitcherButton collapse={collapse} />
        <MenuList shadow="lg" py="2" color={mode("gray.600", "gray.300")} px="2">
          <MenuItem
            rounded="md"
            onClick={() => {
              history.push("/admin/change-password");
            }}
          >
            Change Password
          </MenuItem>
          <MenuItem
            rounded="md"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};

const AccountSwitcherButton = () => {
  const {collapse, user} = useContext(SidebarContext);
  const buttonProps = useMenuButton();
  return (
    <Flex as="button" {...buttonProps} w="full" display="flex" justifyContent="center" alignItems="center" rounded="lg" bg={mode("gray.200", "gray.600")} p="2" fontSize="md" userSelect="none" cursor="pointer" outline="0" transition="all 0.2s" _active={{bg: mode("gray.200", "gray.600")}} _focus={{shadow: "outline"}}>
      <HStack flex="1" spacing="3">
        <>
          {user.data ? (
            <>
              <Image h="8" w="8" borderRadius="md" objectFit="cover" src={user.photo} fallback={<BiUserCircle size="1.8rem" />} alt="" />
              {!collapse && (
                <Box textAlign="start">
                  <Box isTruncated fontWeight="semibold">
                    {user?.data[0]?.username}
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <>
              <SkeletonCircle size="7" />
              {!collapse && <Skeleton w="5rem" h="1rem"></Skeleton>}
            </>
          )}
        </>
      </HStack>
      {!collapse && (
        <Box fontSize="lg" color={mode("gray.800", "gray.400")}>
          <HiSelector />
        </Box>
      )}
    </Flex>
  );
};