import {Box, HStack, Text, useBreakpointValue, VStack} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {convertToKhmerMonth, formatNumberTwoDigit} from "../utils/helper";
import {useTranslation} from "react-i18next";
const calculateTimeLeft = (targetDate) => {
  const date = targetDate.replace(/-/g, "/");
  const difference = new Date(date) - new Date();
  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      days: Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24))),
      hours: Math.max(0, Math.floor((difference / (1000 * 60 * 60)) % 24)),
      minutes: Math.max(0, Math.floor((difference / 1000 / 60) % 60)),
      seconds: Math.max(0, Math.floor((difference / 1000) % 60)),
    };
  }
  return timeLeft;
};

const CountdownTimer = ({timeShow, isOnTime, isLoading, ...props}) => {
  const targetDate = timeShow;
  const [sentToParent, setSentToParent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(timeShow ? targetDate : ""));
  const dateNumSize = {base: "26px", md: "36px", lg: "42px"};
  const dateTextSize = {base: "16px", md: "21px", lg: "26px"};
  const [displayDate, setDisplayDate] = useState("");
  // eslint-disable-next-line
  const md = useBreakpointValue({base: true, md: false}); // responsive
  const {t} = useTranslation(); // i18next

  useEffect(() => {
    if (targetDate === undefined) return;
    const [datePart, timePart] = targetDate?.split(" ");
    const [year, month, day] = datePart?.split("-");
    let [hour, minute] = timePart?.split(":");
    // check AM or PM
    // eslint-disable-next-line
    let time = "ព្រឹក";
    // eslint-disable-next-line
    if (hour > 12) {
      // eslint-disable-next-line
      time = "ល្ងាច";
      hour -= 12;
    }
    setDisplayDate({
      hour: hour,
      minute: minute,
      year: year,
      month: convertToKhmerMonth(month),
      day: day,
      time: time,
    });
  }, [targetDate]);
  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft(targetDate);
      setTimeLeft(timeLeft); // set seconds to 00 before render
      if (!sentToParent && Object.values(timeLeft).every((val) => val === 0)) {
        isOnTime(true);
        setSentToParent(true);
        clearInterval(timer);
      } else setTimeLeft(timeLeft);
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [sentToParent, isOnTime]);
  if (isLoading || timeShow === undefined) return null;
  return (
    <VStack mb={5}>
      {displayDate !== "" && (
        <Box w="full" color="#DA232B" fontSize={{base: "20px", md: "28px", lg: "36px"}} textAlign="center" fontWeight="normal" fontFamily="Baphnom" lineHeight={{base: "40px", md: "50px", lg: "70px"}}>
          <Text>{t("timecount.showtitle", {...displayDate})}</Text>
          {md ? (
            <>
              <Text lineHeight={10}>{t("timecount.showdate", {...displayDate})}</Text>
              <Text>{t("timecount.showtime", {...displayDate})}</Text>
            </>
          ) : (
            <>
              {t("timecount.showdate", {...displayDate})} {t("timecount.showtime", {...displayDate})}
            </>
          )}
        </Box>
      )}
      <HStack w={{base: "100vw", xl: "1440px"}} justifyContent="center" fontFamily="industry">
        <VStack pr={{base: "10px", md: "20px", lg: "30px"}} mb={{base: "30px", md: "40px", lg: "50px"}}
        >
          <Text fontSize={{base: "20px", md: "28px", lg: "36px"}} fontFamily="Baphnom" color="#DA232B">
            {t("timecount.title")}
          </Text>
        </VStack>
        <VStack pl={{base: "10px", md: "20px", lg: "30px"}} pr={{base: "30px", md: "40px", lg: "100px"}} width={{base: "0", md: "100px", lg: "120px"}}>
          <Text fontSize={dateNumSize} fontWeight="semibold">
            {timeLeft.days ? formatNumberTwoDigit(timeLeft.days) : "0"}
          </Text>
          <Text fontFamily={"Metal Chrieng"} fontSize={dateTextSize}>
            {t("timecount.time.day")}
          </Text>
        </VStack>
        <HStack>
          <VStack width={{base: "60px", md: "80px", lg: "80px"}} mr={{base: "-10px", md: "-5px", lg: "-5px"}}>
            <Text fontSize={dateNumSize} fontWeight="semibold">
              {timeLeft.hours ? formatNumberTwoDigit(timeLeft.hours) : "00"} :
            </Text>
            <Text fontFamily={"Metal Chrieng"} fontSize={dateTextSize} pr={{base: "10px", md: "20px", lg: "25px"}}>
              {t("timecount.time.hour")}
            </Text>
          </VStack>
          <VStack width={{base: "60px", md: "70px", lg: "80px"}}>
            <Text fontSize={dateNumSize} fontWeight="semibold">
              {timeLeft.minutes ? formatNumberTwoDigit(timeLeft.minutes) : "00"} :
            </Text>
            <Text fontFamily={"Metal Chrieng"} fontSize={dateTextSize} pr={{base: "10px", md: "20px", lg: "25px"}}>
              {t("timecount.time.minute")}
            </Text>
          </VStack>
          <VStack width={{base: "30px", md: "50px", lg: "50px"}}>
            <Text fontSize={dateNumSize} fontWeight="semibold">
              {timeLeft.seconds ? formatNumberTwoDigit(timeLeft.seconds) : "00"}
            </Text>
            <Text fontFamily={"Metal Chrieng"} fontSize={dateTextSize}>
              {t("timecount.time.second")}
            </Text>
          </VStack>
        </HStack>
      </HStack>
      {/* <Text textAlign="center" w="full" fontFamily={"Taprom"} fontSize={{base: "14px", md: "18px", lg: "20px"}} color="#DA232B" pt={{base: "10px", md: "20px", lg: "20px"}}>
        {t("root.notfake")}
      </Text> */}
    </VStack>
  );
};

export default CountdownTimer;
