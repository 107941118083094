import { Center, Image } from "@chakra-ui/react";
import { toAbsoluteUrl } from "../../utils/helper";

export default function SplashScreen() {
	return (
		<Center bg="gray.100" h="100vh" w="100vw" pos="relative">
			<Image w="60" pos="sticky" src={toAbsoluteUrl("/assets/Wingo Logo.png")} />
		</Center>
	);
}
