import {Box, VStack, Image, UnorderedList, ListItem} from "@chakra-ui/react";
import {toAbsoluteUrl} from "../../../utils/helper";
import {useTranslation} from "react-i18next";
import React from "react";

export default function Introduction() {
  const {t} = useTranslation();
  return (
    <VStack mb="10px">
      <Content title={t("guidance.title.header")}>
        <Box fontSize={{base: "11px", md: "15px", lg: "19px"}} textAlign="center">
          {t("guidance.title.title")}
        </Box>
        <Box fontSize={{base: "20px", md: "24px", lg: "28px"}} textAlign="center" mt={{base: "10px", md: "20px", lg: "30px"}}>
          {t("guidance.title.brand")}
        </Box>
        <Box color="#A9411B" mt={{base: "10px", md: "20px", lg: "30px"}}>
          {t("guidance.part1.title")}
        </Box>
        <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("guidance.part1.content")}</Box>
        <Box color="#A9411B" mt={{base: "10px", md: "20px", lg: "30px"}}>
          {t("guidance.part2.title")}
        </Box>
        <Box>{t("guidance.part2.option1")}</Box>
        <Box>{t("guidance.part2.option2")}</Box>
        <Box>{t("guidance.part2.option3")}</Box>
        <Box>{t("guidance.part2.option4")}</Box>
        <Box>{t("guidance.part2.option5")}</Box>
        <Box>{t("guidance.part2.option6")}</Box>
        <Box>{t("guidance.part2.option7")}</Box>
        <Box>{t("guidance.part2.exoption7")}</Box>
        <Box>{t("guidance.part2.option8")}</Box>
        <Box color="#A9411B" mt={{base: "10px", md: "20px", lg: "30px"}}>
          {t("guidance.part3.title")}
        </Box>
        <Box fontWeight="bold" mx={{base: "10px", md: "20px", lg: "30px"}}>
          <ul>
            <li>{t("guidance.part3.front")}</li>
          </ul>
        </Box>
        <Box spacing={5} mt={{base: "10px", md: "20px", lg: "30px"}}>
          <Image align="center" src={toAbsoluteUrl("/assets/guidance/Romduol Ticket for June 2024 A4-02.jpg")} />
          <Box fontWeight="bold" m={{base: "10px", md: "20px", lg: "30px"}}>
            <ul>
              <li>{t("guidance.part3.back")}</li>
            </ul>
          </Box>
          <Image align="center" src={toAbsoluteUrl("/assets/guidance/Romduol Ticket for June 2024 A4-03.jpg")} />
        </Box>
        <Box color="#A9411B" mt={{base: "10px", md: "20px", lg: "30px"}}>
          {t("guidance.part4.title")}
        </Box>
        <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("guidance.part4.content")}</Box>
        <VStack spacing={5} mt={{base: "10px", md: "20px", lg: "30px"}}>
          <Image w="full" src={toAbsoluteUrl("/assets/guidance/IMG_1371.PNG")} />
          <Image w="full" src={toAbsoluteUrl("/assets/guidance/IMG_1372.PNG")} />
        </VStack>
        <Box mt={{base: "10px", md: "20px", lg: "30px"}}>
          <Box>{t("guidance.part4.option1")}</Box>
          <Box>{t("guidance.part4.exoption1")}</Box>
          <Box>{t("guidance.part4.option2")}</Box>
          <Box>{t("guidance.part4.exoption2")}</Box>
          <Box>{t("guidance.part4.option3")}</Box>
          <Box>{t("guidance.part4.exoption3")}</Box>
          <Box>{t("guidance.part4.option4")}</Box>
          <Box>{t("guidance.part4.exoption4")}</Box>
          <Box>{t("guidance.part4.option5")}</Box>
          <Box>{t("guidance.part4.exoption5")}</Box>
          <Box>{t("guidance.part4.option6")}</Box>
          <Box>{t("guidance.part4.exoption6")}</Box>
          <Box>{t("guidance.part4.option7")}</Box>
          <Box>{t("guidance.part4.exoption7")}</Box>
          <Box>{t("guidance.part4.option8")}</Box>
          <Box>{t("guidance.part4.exoption8")}</Box>
        </Box>
        <Box color="#A9411B" mt={{base: "10px", md: "20px", lg: "30px"}}>
          {t("guidance.part5.title")}
        </Box>
        <UnorderedList ml={{base: "5", sm: "7"}}>
          <ListItem>{t("guidance.part5.option1")}</ListItem>
          <ListItem>{t("guidance.part5.option2")}</ListItem>
          <ListItem>{t("guidance.part5.option3")}</ListItem>
          <ListItem>{t("guidance.part5.option4")}</ListItem>
          <ListItem>{t("guidance.part5.option5")}</ListItem>
          <ListItem>{t("guidance.part5.option6")}</ListItem>
          <ListItem>{t("guidance.part5.option7")}</ListItem>
          <ListItem>{t("guidance.part5.option8")}</ListItem>
          <ListItem>{t("guidance.part5.option9")}</ListItem>
          <ListItem>{t("guidance.part5.option10")}</ListItem>
          <ListItem>{t("guidance.part5.option11")}</ListItem>
        </UnorderedList>
        <Box color="#A9411B" mt={{base: "10px", md: "20px", lg: "30px"}}>
          {t("guidance.part6.title")}
        </Box>
        <UnorderedList ml={{base: "5", sm: "7"}}>
          <ListItem>{t("guidance.part6.option1")}</ListItem>
          <ListItem>{t("guidance.part6.option2")}</ListItem>
          <ListItem>{t("guidance.part6.option3")}</ListItem>
          <Box>
            <b style={{color: "#000000"}}>{t("guidance.part6.option4")}</b>
            {t("guidance.part6.option5")}
          </Box>
        </UnorderedList>
        <Image w="full" align="center" mt={{base: "10px", md: "20px", lg: "30px"}} src={toAbsoluteUrl("/assets/guidance/Poster V2-02.jpg")} />
        <Box color="#A9411B" mt={{base: "10px", md: "20px", lg: "30px"}}>
          {t("guidance.part7.title")}
        </Box>
        <Box ml="1">
          <Box>{t("guidance.part7.option1")}</Box>
          <Box>{t("guidance.part7.option2")}</Box>
          <Box>{t("guidance.part7.option3")}</Box>
          <Box>{t("guidance.part7.option4")}</Box>
          <Box>{t("guidance.part7.option5")}</Box>
          <Box>{t("guidance.part7.option6")}</Box>
          <Box>{t("guidance.part7.option7")}</Box>
          <Box>{t("guidance.part7.option8")}</Box>
          <Box>{t("guidance.part7.option9")}</Box>
        </Box>
      </Content>
    </VStack>
  );
}
const Content = ({title, children}) => {
  return (
    <Box width="100%">
      <Box fontSize={{base: "20px", md: "24px", lg: "28px"}} borderBottom="solid 1px #A9411B" borderTop="solid 1px #A9411B" fontFamily={"Baphnom"} textAlign="center" lineHeight={{base: "60px", md: "70px", lg: "80px"}} width="95%" mx="auto" my={{base: "20px", md: "30px", lg: "40px"}}>
        {title}
      </Box>
      <Box className="about-content" fontSize={{base: "14px", md: "16px", lg: "18px"}} fontFamily={"Metal Chrieng"} lineHeight={{base: "34px", md: "38px", lg: "42px"}} width="95%" mx={"auto"}>
        {children}
      </Box>
    </Box>
  );
};
