import {forwardRef, useEffect, useMemo, useState} from "react";
import {Table, Box, Tbody, Td, Text, Thead, Tr, Image, Flex, SimpleGrid} from "@chakra-ui/react";
import {convertToKhmerMonth, toAbsoluteUrl} from "../../utils/helper";
import {StyledTableRow} from "./TableLiveResult.jsx";
import {useTranslation} from "react-i18next";
import "../../App.css";

const TablePrintTemplate = forwardRef(({timeLastTable, data, ...props}, ref) => {
  const {t} = useTranslation();
  const [result, setResult] = useState(null);

  const TableData = useMemo(() => {
    return (
      data?.map((entry) => ({
        id: entry.id,
        winningName: entry.winningName,
        winningLevel: entry.winningLevel,
        winningPrice: entry.winningPrice,
        totalSwingTime: entry.totalSwingTime,
        digitNumber: entry.digitNumber,
        winningResult: entry.winningResult,
      })) || []
    );
  }, [data]);

  useEffect(() => {
    if (data == null || data === undefined) return;
    let newResult = [];
    for (let i = 0; i < TableData.length; i++) {
      newResult[TableData[i].id] = data[i]?.winningResult;
    }
    setResult(newResult);
  }, [data, TableData]);

  const title = (timeLastTable) => {
    let titleHeader;
    if (timeLastTable !== null && timeLastTable !== undefined) {
      const [datePart, timePart] = timeLastTable.split(" ");
      const [year, month, day] = datePart.split("-");
      const [hour, min] = timePart.split(":");
      titleHeader = [
        <Box>{t("history.header1")}</Box>,
        <Box>
          {t("history.header2", {
            day: day,
            month: convertToKhmerMonth(month),
            year: year,
            hour: hour > 12 ? hour - 12 : hour,
            min: min,
            period: hour > 12 ? "ល្ងាច" : "ព្រឹក",
          })}
        </Box>,
      ];
    } else {
      titleHeader = `លទ្ធផលឆ្នោត - ថ្ងៃទី- ខែ- ឆ្នាំ-`;
    }
    return [titleHeader];
  };

  if (data == null || data === undefined) return <p ref={ref} {...props}></p>;

  return (
    <Box ref={ref} display={"flex"} flexDir={"column"} {...props} bg={"white"} width={"760px"} p="5px 10px">
      <Box mb={"10px"} display="flex" flexDir={"row"} justifyContent={"center"} alignItems={"center"} position="relative">
        <Image src={toAbsoluteUrl("/assets/Wingo Logo.png")} width={"60px"} position={"absolute"} left={"0"} />
        <Box display={"flex"} flexDirection={"column"} color="#DA232B" fontSize={{base: "20px"}} fontFamily="Baphnom" justifyContent={"center"} alignItems={"center"}>
          {title(timeLastTable)}
        </Box>
      </Box>
      <Table bg="white" whiteSpace="nowrap" width={"100%"}>
        <Thead width={"100%"}>
          <Tr fontSize={"16px"} fontWeight="normal" fontFamily="Moul Light" color="#000" bg="#C9AB89" px={0}>
            <Td borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" px={1}>
              {t("root.rankreward")}
            </Td>
            <Td borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center">
              {t("root.rewardprice")}
            </Td>
            <Td borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center">
              {t("root.result")}
            </Td>
          </Tr>
        </Thead>
        <Tbody fontFamily="Baphnom">
          {TableData.map((entry) => (
            <StyledTableRow key={entry.id}>
              <Td borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" py="3px" fontSize="16px">
                {t("root.reward")} {entry.winningLevel}
              </Td>
              <Td borderWidth="1px" lineHeight="25px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" p="0">
                {entry.winningName}
                <Text fontSize={"16px"}>{entry.winningPrice.toLocaleString()} ៛</Text>
              </Td>
              <Td borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" p={0} height={"56px"}>
                {((entry.winningLevel >= 2 && entry.id <= 17) || (entry.winningLevel >= 2 && entry.id >= 21)) && (
                  <SimpleGrid templateColumns="repeat(3, 1fr)" ml={"-1px"}>
                    {[...Array(entry.totalSwingTime)].map((_, index) => (
                      <Box key={index} borderWidth={"1px"} borderColor="#e0e0e0" borderStyle="solid" display={"inline-flex"} flexWrap="wrap" justifyContent="center" pr={"2px"} pt={"12px"} pb={"9px"} mt={"-1px"} mb={"-1px"} mr={"-1px"}>
                        <Flex justifyContent="center" alignItems="center">
                          {[...Array(entry.digitNumber)].map((_, dIndex) => {
                            const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                            const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                            const fontSize = entry.winningLevel === 1;
                            return (
                              <Box key={dIndex} my="7px">
                                {isResultVisible && (
                                  <Box fontFamily="georgiapro-condblack" mx="1px" textAlign="center" justifyContent="center" alignItems="center" fontSize={fontSize ? "2.5em" : "1.8em"}>
                                    <Text color="#000">{char}</Text>
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                        </Flex>
                      </Box>
                    ))}
                    <Box borderWidth={"1px"} borderColor="#e0e0e0" borderStyle="solid" pr={"2px"} pt={"0px"} pb={"0px"} mt={"-1px"} mb={"-1px"} mr={"-1px"}></Box>
                  </SimpleGrid>
                )}
                {entry.totalSwingTime >= 2 && entry.id >= 18 && entry.id <= 19 && (
                  <SimpleGrid templateColumns="repeat(3, 1fr)" ml={"-1px"}>
                    {[...Array(entry.totalSwingTime)].map((_, index) => (
                      <Box key={index} borderWidth={"1px"} borderColor="#e0e0e0" borderStyle="solid" display={"inline-flex"} flexWrap="wrap" justifyContent="center" pr={"2px"} pt={"12px"} pb={"9px"} mt={"-1px"} mb={"-1px"} mr={"-1px"}>
                        <Flex justifyContent="center" alignItems="center">
                          {[...Array(entry.digitNumber)].map((_, dIndex) => {
                            const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                            const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                            const fontSize = entry.winningLevel === 1;
                            return (
                              <Box key={dIndex} my="7px">
                                {isResultVisible && (
                                  <Box fontFamily="georgiapro-condblack" mx="1px" textAlign="center" justifyContent="center" alignItems="center" fontSize={fontSize ? "2.5em" : "1.8em"}>
                                    <Text color="#000">{char}</Text>
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                        </Flex>
                      </Box>
                    ))}
                    <Box borderWidth={"1px"} borderColor="#e0e0e0" borderStyle="solid" pr={"2px"} pt={"0px"} pb={"0px"} mt={"-1px"} mb={"-1px"} mr={"-1px"}></Box>
                  </SimpleGrid>
                )}
                {entry.totalSwingTime <= 1 && entry.id >= 18 && entry.id <= 19 && (
                  <Box>
                    {[...Array(entry.totalSwingTime)].map((_, index) => {
                      return (
                        <Box key={index} display={"inline-flex"} width="100%" flexWrap="wrap" justifyContent="center">
                          <Flex float="left" width="33%" justifyContent="center" alignItems="center">
                            {[...Array(entry.digitNumber)].map((_, dIndex) => {
                              const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                              const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                              const fontSize = entry.winningLevel === 1;
                              return (
                                <Box key={dIndex} my="7px">
                                  {isResultVisible ? (
                                    <Box fontFamily="georgiapro-condblack" mx="1px" textAlign="center" justifyContent="center" alignItems="center" fontSize={fontSize ? "2.5em" : "1.8em"}>
                                      <Text color="#000">{char}</Text>
                                    </Box>
                                  ) : (
                                    <Box width={{base: "20px"}} mx=".5px">
                                      <Image src={toAbsoluteUrl("/assets/120.gif")} alt="blank" width="100%" />
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                {entry.winningLevel === 1 && (
                  <Box>
                    {[...Array(entry.totalSwingTime)].map((_, index) => {
                      return (
                        <Box key={index} display={"inline-flex"} width="100%" flexWrap="wrap" justifyContent="center">
                          <Flex float="left" width="33%" justifyContent="center" alignItems="center">
                            {[...Array(entry.digitNumber)].map((_, dIndex) => {
                              const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                              const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                              const fontSize = entry.winningLevel === 1;
                              return (
                                <Box key={dIndex} my="7px">
                                  {isResultVisible ? (
                                    <Box fontFamily="georgiapro-condblack" mx="1px" textAlign="center" justifyContent="center" alignItems="center" fontSize={fontSize ? "2.5em" : "1.8em"}>
                                      <Text color="#000">{char}</Text>
                                    </Box>
                                  ) : (
                                    <Box width={{base: "20px"}} mx=".5px">
                                      <Image src={toAbsoluteUrl("/assets/120.gif")} alt="blank" width="100%" />
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Td>
            </StyledTableRow>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
});

export default TablePrintTemplate;
