import {Box, Center, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import CountdownTimer from "../../../components/CountdownTimer";
import TableHistoryResult from "../../../components/Tables/TableHistoryResult";
import TableLiveResult from "../../../components/Tables/TableLiveResult";
import {calculateTimeDifferenceInSecond} from "../../../utils/helper";
import {useGetResult, useGetTargetDate} from "../../../utils/hooks/live-table";

// for testing
const staticTesting = false;
const time = "2024-05-09 09:33:00";

export default function HomePage() {
  const [timeLiveTable, setTimeLiveTable] = useState("");
  const [targetTimeTable, setTargetTimeTable] = useState("");
  const [showLiveTable, setShowLiveTable] = useState(false);
  const [dataLastResult, setDataLastResult] = useState({});
  const [dataLiveResult, setDataLiveResult] = useState({});
  const [isOnTime, setOnTime] = useState(false);

  const getAllDateTime = useGetTargetDate();
  const getLastResult = useGetResult({
    payload: targetTimeTable,
  });
  const getLiveResult = useGetResult({
    payload: targetTimeTable,
  });

  useEffect(() => {
    if (getAllDateTime.data && getAllDateTime.isSuccess) {
      const data = getAllDateTime.data;
      setTimeLiveTable(data.timeLiveTable);

      const differenceTimeInSecond = calculateTimeDifferenceInSecond(staticTesting ? time : data.timeLiveTable);
      const timeInMinute = differenceTimeInSecond / 60;
      let timeToLive = (differenceTimeInSecond + 1 - 1800) * 1000;

      if (timeInMinute > 30) {
        setTargetTimeTable(data.timeLastTable);
        setTimeout(() => {
          setShowLiveTable(true);
          setTargetTimeTable(data.timeLiveTable);
        }, timeToLive);
      } else {
        setTargetTimeTable(data.timeLiveTable);
        setShowLiveTable(true);
      }
    }
    // eslint-disable-next-line
  }, [getAllDateTime.data]);

  useEffect(() => {
    if (getLastResult.data) {
      setDataLastResult(getLastResult.data);
    }
  }, [getLastResult.data, setDataLastResult]);

  useEffect(() => {
    if (getLiveResult.data) {
      setDataLiveResult(getLiveResult.data);
    }
  }, [getLiveResult.data, setDataLiveResult]);

  useEffect(() => {
    let intervalId = setInterval(() => {
      getAllDateTime.refetch();
    }, 300000); // Refetch data every 5 minutes

    return () => clearInterval(intervalId);
  }, [getAllDateTime]);

  const handleOnTime = (data) => setOnTime(data);

  return (
    <VStack my="15px" px={{base: "8px"}} color="#000">
      <Box width="100%">
        {timeLiveTable && <CountdownTimer timeShow={staticTesting ? time : timeLiveTable} isOnTime={handleOnTime} isLoading={getAllDateTime.isLoading} handleOnTime={handleOnTime} />}
        {targetTimeTable !== "" && getAllDateTime.isSuccess ? (
          showLiveTable ? (
            <TableLiveResult timeShow={staticTesting ? time : targetTimeTable} staticTesting={staticTesting} isOnTime={isOnTime} dataResponse={dataLiveResult} />
          ) : getLastResult.isSuccess ? (
            <>
              <TableHistoryResult scheduleTime={targetTimeTable} dataResponse={dataLastResult} isLiveModule={true} />
            </>
          ) : (
            <Center>សូមរង់ចាំ ...</Center>
          )
        ) : (
          <Center>សូមរង់ចាំ ...</Center>
        )}
      </Box>
    </VStack>
  );
}
