import {Box, Flex} from "@chakra-ui/react";
import {AnimatePresence, motion} from "framer-motion";
import React, {Suspense} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import {SplashScreen} from "../../components/Loader";
import Sidebar from "../../components/Sidebar";

const DelayImport = (path) => {
  return React.lazy(() => {
    return Promise.all([path, new Promise((resolve) => setTimeout(resolve, 300))]).then(([moduleExports]) => moduleExports);
  });
};

const PromotionFeed = DelayImport(import("./promotion"));
const MonthlyWinner = DelayImport(import("./monthly-winner"));
const ChangePassword = DelayImport(import("./change-password"));

// setting
const routeItem = [
  {path: "/admin/promotion", component: <PromotionFeed />},
  {path: "/admin/monthly-winner", component: <MonthlyWinner />},
  {path: `/admin/change-password`, component: <ChangePassword />},
];

const MotionBox = motion(Box);
export default function App() {
  const location = useLocation();
  return (
    <Suspense fallback={<SplashScreen />}>
      <Flex position="relative" h="100vh" overflow="auto" id="app-container">
        <Box display="flex">
          <Sidebar />
        </Box>
        <Box w={"calc(100% - 18rem)"} p="2" position="relative" flex="1">
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              {routeItem.map(({path, component}) => (
                <Route key={path} path={path}>
                  <MotionBox h="full" overflow="auto" rounded="md" key={location.pathname} initial={{y: -10, opacity: 0, transition: {duration: 0.15}}} animate={{y: 0, opacity: 1, transition: {duration: 0.2}}} exit={{y: -20, opacity: 0, transition: {duration: 0.15}}}>
                    {component}
                  </MotionBox>
                </Route>
              ))}
            </Switch>
          </AnimatePresence>
        </Box>
      </Flex>
    </Suspense>
  );
}
