import {Image, Box, Flex, Container} from "@chakra-ui/react";
import React from "react";
import {toAbsoluteUrl} from "../../utils/helper";
import {AnimatePresence, motion} from "framer-motion";
import HomePage from "./pages/HomePage";
import Guidance from "./pages/Guidance";
import LegalDocument from "./pages/LegalDocument";
import PreviousResults from "./pages/PreviousResults";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import "../../App.css";
import AboutCompany from "./pages/AboutCompany";
import Navbar from "../../components/navbar";
import NavBottom from "../../components/navBottom.jsx";
const routeItem = [
  {path: "/home", component: <HomePage />},
  {path: "/guidance", component: <Guidance />},
  {path: "/legality", component: <LegalDocument />},
  {path: "/previous-result", component: <PreviousResults />},
  {path: "/about-company", component: <AboutCompany />},
];
const MotionBox = motion(Box);

export default function BaseSitePage() {
  const location = useLocation();

  return (
    <Box bg="#EDDBCD" backgroundImage={toAbsoluteUrl("/assets/bg_patterns.svg")} width="100%" id="no-print">
      <Navbar />
      <Flex bg="#DA232B" pb={{base: 1, md: 3, lg: 5}} mb={{base: 5, md: 7, lg: 10}}>
        <Container maxW="1440px" align="center">
          <Image id="romduol-banner" src={toAbsoluteUrl("/assets/romduol-banner.png")} alt="romduol-banner" width="80%" />
        </Container>
      </Flex>
      <Box maxWidth="1440px" mx="auto" minHeight="100vh">
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            {routeItem.map(({path, component}) => (
              <Route key={path} path={path}>
                <MotionBox key={location.pathname} initial={{y: -10, opacity: 0, transition: {duration: 0.15}}} animate={{y: 0, opacity: 1, transition: {duration: 0.2}}} exit={{y: -20, opacity: 0, transition: {duration: 0.15}}}>
                  {component}
                </MotionBox>
              </Route>
            ))}
            <Redirect to="/home" />
          </Switch>
        </AnimatePresence>
      </Box>
      <NavBottom />
    </Box>
  );
}
