import {Box, Center, Flex, Image, SimpleGrid, Table, Tbody, Td, Text, Thead, Tr} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {useCallback, useEffect, useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {dataObject} from "../../constants/variables";
import {calculateDifferenceTime, randomGifNum, toAbsoluteUrl} from "../../utils/helper";
import {useGetResult} from "../../utils/hooks/live-table";

// styled
export const StyledTableRow = styled(Tr)(() => ({
  "&:nth-of-type(odd)": {backgroundColor: "#E7E8E9"},
}));

// unit duration that each digit appear in seconds
const unit = 3;

export default function TableLiveResult({isStatic = false, dataResponse = [], records, isOnTime, isLoading, timeShow, ...props}) {
  const {t} = useTranslation();

  // all winning Level (phase)
  const phase = useMemo(() => (Array.isArray(dataResponse) ? dataResponse.map((entry) => entry.id) : []), [dataResponse]);

  const TableData = useMemo(() => {
    return Array.isArray(dataResponse)
      ? dataResponse.map((entry) => ({
          id: entry.id,
          winningName: entry.winningName,
          winningLevel: entry.winningLevel,
          winningPrice: entry.winningPrice,
          totalSwingTime: entry.totalSwingTime,
          digitNumber: entry.digitNumber,
          winningResult: entry.winningResult,
        }))
      : [];
  }, [dataResponse]);

  // choose appropriate digit and level to be render corresponding to time
  // this will help to give an illusion of live streaming from the database
  const getResultByTime = useCallback(
    (seconds, records) => {
      let newResult = {};
      let targetIndex = 0;
      if (seconds < 0 || !Array.isArray(records) || records[0] === undefined) return {newResult, targetIndex};

      for (let i = 0; i < TableData.length; i++) {
        const tableEntry = TableData[i];
        if (!tableEntry || records[i]?.winningResult === undefined) return {newResult, targetIndex};

        let digitSecond = tableEntry.digitNumber * tableEntry.totalSwingTime * unit;
        targetIndex = i;

        if (seconds < digitSecond && !isStatic) {
          break;
        }
        seconds -= digitSecond;
        newResult[tableEntry.id] = records[i].winningResult;
      }

      if (targetIndex + 1 >= TableData.length) return {newResult, targetIndex};

      let theRest = [];
      const tableEntry = TableData[targetIndex];
      if (!tableEntry) return {newResult, targetIndex};

      let digitSecond = tableEntry.digitNumber * unit;

      for (let i = 0; i < tableEntry.totalSwingTime; i++) {
        let current = records[targetIndex]?.winningResult[i];
        if (!current) continue;

        if (seconds >= digitSecond) {
          seconds -= digitSecond;
          theRest.push(current);
        } else {
          theRest.push(current.slice(0, Math.floor(seconds / unit)));
          break;
        }
      }

      newResult[tableEntry.id] = theRest;
      return {newResult, targetIndex, seconds: seconds % unit};
    },
    [TableData, isStatic],
  );

  const [result, setResult] = useState({});
  const [phaseState, setPhaseState] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isDone, setIsDone] = useState(false);
  const Result = useGetResult({
    payload: timeShow,
    config: {
      enabled: !!(timeShow && isOnTime) || isStatic,
      refetchInterval: 16 * 60 * 1000, // refetch after 15mn
    },
  });
  // this will continue to update the data every unit of seconds after the getResultByTime has been run
  const updateResult = useCallback(
    (data) => {
      let tmp = {...result};
      let currentPhase = phase[phaseState];
      let tmpState = phaseState;
      let table = TableData.find((x) => x.id === currentPhase);

      if (data === null || data === undefined || table === undefined) {
        setIsDone(true);
        return tmp;
      }

      if (!tmp[currentPhase]) {
        tmp[currentPhase] = [];
      }

      let arrLength = tmp[currentPhase].length;

      if (data[phaseState]?.winningResult === undefined) return tmp;

      if (arrLength === 0 || (tmp[currentPhase][arrLength - 1] && tmp[currentPhase][arrLength - 1].length === table.digitNumber)) {
        const newValue = data[phaseState].winningResult[arrLength]?.charAt(0);
        if (newValue !== undefined) {
          tmp[currentPhase].push(newValue);
        }
      } else if (tmp[currentPhase][arrLength - 1]) {
        const nextChar = data[phaseState].winningResult[arrLength - 1]?.charAt(tmp[currentPhase][arrLength - 1].length);
        if (nextChar !== undefined) {
          tmp[currentPhase][arrLength - 1] += nextChar;
        }
      }

      if (arrLength === table.totalSwingTime && tmp[currentPhase][arrLength - 1]?.length === table.digitNumber) {
        currentPhase = phase[phaseState + 1];
        tmpState = phaseState + 1;
      }

      setPhaseState(tmpState);
      return tmp;
    },
    [phaseState, phase, result, TableData],
  );

  useEffect(() => {
    const data = props.staticTesting ? dataObject : Result?.data;
    if (data) {
      const obj = getResultByTime(calculateDifferenceTime(timeShow), data);
      setResult(obj.newResult);
      setPhaseState(obj.targetIndex);
      setSeconds(obj.seconds);
    }
  }, [Result.data, isOnTime, timeShow, getResultByTime, props.staticTesting]);

  // set timer every unit seconds
  useEffect(() => {
    const data = props.staticTesting ? dataObject : Result?.data;
    let timeout = null;
    if (isOnTime && !isDone) {
      let time = seconds > 0 ? seconds * 1000 : unit * 1000;
      timeout = setTimeout(() => {
        setResult(updateResult(data));
        setSeconds(0);
      }, time);
    }
    return () => clearTimeout(timeout);
  }, [result, Result.data, isOnTime, isDone, updateResult, seconds, props.staticTesting]);

  if (isStatic && Result?.data?.[0]?.winningResult === undefined) return null;
  return (
    <Center>
      <Table bg="white" whiteSpace="nowrap" px={0} borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid">
        <Thead bgColor="#c8aa88" color="#000">
          <Tr fontSize={{base: "12px", sm: "20px", md: "25px", lg: "30px"}} fontFamily="Moul Light" height={{base: "", sm: "40px", md: "60px", lg: "80px"}} px={0}>
            <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" px={{base: 1, md: 6, lg: 6}}>
              {t("root.rankreward")}
            </Td>
            <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center">
              {t("root.rewardprice")}
            </Td>
            <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center">
              {t("root.result")}
            </Td>
          </Tr>
        </Thead>
        <Tbody fontFamily="Baphnom">
          {TableData.map((entry) => (
            <StyledTableRow key={entry.id}>
              <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" fontSize={{base: "12px", md: "20px", lg: "24px"}} p="0">
                {t("root.reward")} {entry.winningLevel}
              </Td>
              <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" fontSize={{base: "12px", md: "20px", lg: "24px"}} lineHeight={{base: "20px", md: "30px", lg: "40px"}} p="0">
                {entry.winningName}
                <Text fontSize={{base: "16px", md: "20px", lg: "24px"}}>{entry.winningPrice?.toLocaleString()} ៛</Text>
              </Td>
              <Td borderWidth="0px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" p={0} bgPos={"start center"} bgSize="auto 100%" bgRepeat={"no-repeat"} bgImage={toAbsoluteUrl(entry.winningLevel === 1 && result?.[entry.id] && result?.[entry.id][0].length === entry.digitNumber ? "/assets/firstprize.gif" : "")}>
                {((entry.winningLevel >= 2 && entry.id <= 17) || (entry.winningLevel >= 2 && entry.id >= 21)) && (
                  <SimpleGrid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(2, 1fr)", xl: "repeat(3, 1fr)"}}>
                    {[...Array(entry.totalSwingTime)].map((_, index) => {
                      const height = {base: "60px", md: "100px", lg: "120px"};
                      return (
                        <Box borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}} key={index}>
                          <Flex key={index} height={height} justifyContent="center" alignItems="center">
                            {[...Array(entry.digitNumber)].map((_, dIndex) => {
                              const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                              const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                              const width = {base: "20px", md: "30px", lg: "40px"};
                              const fontSize = {base: "26px", md: "40px", lg: "52px"};
                              return (
                                <Box key={dIndex}>
                                  {isResultVisible ? (
                                    <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                      {char}
                                    </Text>
                                  ) : (
                                    <Image width={width} src={randomGifNum()} alt="randomGifNum" />
                                  )}
                                </Box>
                              );
                            })}
                          </Flex>
                        </Box>
                      );
                    })}
                    <Box borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}}></Box>
                  </SimpleGrid>
                )}
                {entry.totalSwingTime >= 2 && entry.id >= 18 && entry.id <= 19 && (
                  <SimpleGrid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(2, 1fr)", xl: "repeat(3, 1fr)"}}>
                    {[...Array(entry.totalSwingTime)].map((_, index) => {
                      const height = {base: "60px", md: "100px", lg: "120px"};
                      return (
                        <Box borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}} key={index}>
                          <Flex key={index} height={height} justifyContent="center" alignItems="center">
                            {[...Array(entry.digitNumber)].map((_, dIndex) => {
                              const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                              const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                              const width = {base: "20px", md: "30px", lg: "40px"};
                              const fontSize = {base: "26px", md: "40px", lg: "52px"};
                              return (
                                <Box key={dIndex}>
                                  {isResultVisible ? (
                                    <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                      {char}
                                    </Text>
                                  ) : (
                                    <Image width={width} src={randomGifNum()} alt="randomGifNum" />
                                  )}
                                </Box>
                              );
                            })}
                          </Flex>
                        </Box>
                      );
                    })}
                    <Box borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}}></Box>
                  </SimpleGrid>
                )}
                {entry.totalSwingTime <= 1 && entry.id >= 18 && entry.id <= 19 && (
                  <Box>
                    {[...Array(entry.totalSwingTime)].map((_, index) => {
                      const height = {base: "60px", md: "100px", lg: "120px"};
                      return (
                        <Box mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}} key={index}>
                          <Flex key={index} height={height} justifyContent="center" alignItems="center">
                            {[...Array(entry.digitNumber)].map((_, dIndex) => {
                              const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                              const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                              const width = {base: "20px", md: "30px", lg: "40px"};
                              const fontSize = {base: "26px", md: "40px", lg: "52px"};
                              return (
                                <Box key={dIndex}>
                                  {isResultVisible ? (
                                    <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                      {char}
                                    </Text>
                                  ) : (
                                    <Image width={width} src={randomGifNum()} alt="randomGifNum" />
                                  )}
                                </Box>
                              );
                            })}
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                {entry.winningLevel === 1 && (
                  <Box>
                    {[...Array(entry.totalSwingTime)].map((_, index) => {
                      const height = {base: "60px", md: "100px", lg: "120px"};
                      return (
                        <Box mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}} key={index}>
                          <Flex key={index} height={height} justifyContent="center" alignItems="center">
                            {[...Array(entry.digitNumber)].map((_, dIndex) => {
                              const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                              const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                              const widthP1 = {base: "26px", md: "40px", lg: "65px"};
                              const fontSizeP1 = {base: "34px", md: "60px", lg: "82px"};
                              return (
                                <Box key={dIndex}>
                                  {isResultVisible ? (
                                    <Text fontFamily="georgiapro-condblack" fontSize={fontSizeP1} width={widthP1} color="#000">
                                      {char}
                                    </Text>
                                  ) : (
                                    <Image width={widthP1} src={randomGifNum()} alt="randomGifNum" />
                                  )}
                                </Box>
                              );
                            })}
                          </Flex>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Td>
            </StyledTableRow>
          ))}
        </Tbody>
      </Table>
    </Center>
  );
}
