import { QueryClient } from "react-query";
import { storage } from "../utils/helper";

const queryErrorHandler = () => {
   if (storage.getToken()) {
      alert("Error");
   }
};

const defaultQueryClientOptions = {
   queries: {
      onError: queryErrorHandler, retry: 2, staleTime: 600000, cacheTime: 900000,
   }, mutations: {
      onError: queryErrorHandler,
   },
};

export const queryClient = new QueryClient({
   defaultOptions: defaultQueryClientOptions,
});
