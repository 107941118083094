// setting
export const AUTH_USER = "AUTH_USER";
export const GET_USER = "GET_USER";
export const FIND_USER = "FIND_USER";

export const GET_SYSTEM_ROLE = "GET_SYSTEM_ROLE";
export const FIND_SYSTEM_ROLE = "FIND_SYSTEM_ROLE";

export const GET_PRIVACY = "GET_PRIVACY";
export const FIND_PRIVACY = "FIND_PRIVACY";

export const FIND_ABOUT_US = "FIND_ABOUT_US";

export const GET_INFINITE_SLIDE = "GET_INFINITE_SLIDE";
export const FIND_SLIDE = "FIND_SLIDE";

export const GET_INFINITE_PROMOTION_FEED = "GET_INFINITE_FEED";
export const FIND_PROMOTION_FEED = "FIND_FEED";

export const GET_MODULE_TYPE = "GET_MODULE_TYPE";

export const GET_USER_DROPDOWN = "GET_USER_DROPDOWN";

export const GET_USER_DELETE_REASON_DROPDOWN = "GET_USER_DELETE_REASON_DROPDOWN";
export const GET_INFINITE_USER_DELETE_REQUEST = "GET_INFINITE_USER_DELETE_REQUEST";

export const GET_INFINITE_CUSTOMER_SERVICE = "GET_INFINITE_CUSTOMER_SERIVCE";
export const FIND_CUSTOMER_SERVICE = "FIND_CUSTOMER_SERVICE";

export const GET_ICON_DROPDOWN = "GET_ICON_DROPDOWN";

export const GET_ANNUAL_EVENT = "GET_ANNUAL_EVENT";
export const FIND_WINNER_FEED = "FIND_ANNUAL_EVENT";
export const FIND_THUMBNAIL = "FIND_THUMBNAIL";
export const GET_INFINITE_WINNER_FEED = "GET_INFINITE_ANNUAL_EVENT";

// KEY WEBSITE
export const TARGET_DATE = "TARGET_DATE";
export const GET_RESULT = "GET_RESULT";
export const GET_HISTORY_RESULT = "GET_HISTORY_RESULT";
