import { useInfiniteQuery, useQuery } from "react-query";
import { GET_HISTORY_RESULT, GET_RESULT, TARGET_DATE } from "../../constants/queryKeys";
import { getHistoryResult, getResult, getTargetDate } from "../apiClient/live-table";

export const useGetTargetDate = () => {
  return useQuery(TARGET_DATE, () => getTargetDate());
};
export const useGetResult = ({ payload, config }) => {
  return useQuery([GET_RESULT, payload], () => getResult(payload), config);
};
export const useGetInfiniteHistoryResult = ({ payload, config }) => {
  return useInfiniteQuery([GET_HISTORY_RESULT, payload], getHistoryResult, config);
};
