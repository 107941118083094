import {Box, Center, Flex, SimpleGrid, Table, Tbody, Td, Text, Thead, Tr, useBreakpointValue} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {calculateDifferenceTime, convertToKhmerMonth, toAbsoluteUrl} from "../../utils/helper";

// styled
export const StyledTableRow = styled(Tr)(() => ({
  "&:nth-of-type(odd)": {backgroundColor: "#E7E8E9"},
}));

// unit duration that each digit appear in seconds
const unit = 3;

// all winning Level (phase)
// const phase = ["p12", "p11", "p10", "p9", "p8", "p7", "p6", "p5", "p4", "p3", "p2", "p1"];

export default function TableHistoryResult({dataResponse = [], scheduleTime, isLiveModule}) {
  const targetDate = scheduleTime;
  const [displayDate, setDisplayDate] = useState("");
  const {t} = useTranslation();
  const xl = useBreakpointValue({base: true, md: true, lg: true, xl: false});
  const phase = useMemo(() => (Array.isArray(dataResponse) ? dataResponse.map((entry) => entry.id) : []), [dataResponse]);
  const TableData = useMemo(() => {
    return Array.isArray(dataResponse)
      ? dataResponse.map((entry) => ({
          id: entry.id,
          winningName: entry.winningName,
          winningLevel: entry.winningLevel,
          winningPrice: entry.winningPrice,
          totalSwingTime: entry.totalSwingTime,
          digitNumber: entry.digitNumber,
          winningResult: entry.winningResult,
        }))
      : [];
  }, [dataResponse]);
  // choose appropriate digit and level to be render corresponding to time
  // this will help to give an illusion of live streaming from the database
  const getResultByTime = useCallback(
    (seconds, records) => {
      let newResult = {};
      let targetIndex = 0;
      if (seconds < 0 || records[0] === undefined) return {newResult, targetIndex};

      // identify which winning level to show
      for (let i = 0; i < TableData.length; i++) {
        if (records[i].winningResult === undefined) return {newResult, targetIndex};
        let digitSecond = TableData[i].digitNumber * TableData[i].totalSwingTime * unit;
        let tmpSecond = seconds;
        targetIndex = i;

        if (tmpSecond < digitSecond) {
          break;
        }
        seconds -= digitSecond;
        newResult[TableData[i].id] = records[i].winningResult;
      }
      // if all the layer has been shown just return
      if (targetIndex + 1 >= 12) return {newResult, targetIndex};

      //  create array for the rest in that layer that need to be format
      let theRest = [];

      let digitSecond = TableData[targetIndex].digitNumber * unit;

      for (let i = 0; i < TableData[targetIndex].totalSwingTime; i++) {
        let current = records[targetIndex]?.winningResult[i];
        if (seconds >= digitSecond) {
          seconds -= digitSecond;
          theRest.push(current);
        } else {
          theRest.push(current.slice(0, Math.floor(seconds / unit)));
          break;
        }
      }
      newResult[TableData[targetIndex].id] = theRest;
      return {newResult, targetIndex, seconds: seconds % unit};
    },
    [TableData],
  );

  const [result, setResult] = useState({});
  const [phaseState, setState] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isDone, setIsDone] = useState(false);

  const updateResult = useCallback(
    (data) => {
      let tmp = {...result}; // collect previous
      let currentPhase = phase[phaseState];
      let arrLength;
      let tmpState = phaseState;
      let table = TableData.find((x) => x.id === phase[phaseState]);
      if (data === null || data === undefined) {
        return tmp;
      }
      if (table === undefined) {
        setIsDone(true);
        return tmp;
      }

      if (!tmp[currentPhase]) {
        tmp[currentPhase] = [];
      }

      arrLength = tmp[currentPhase].length; // index of data in field

      if (!data[phaseState]?.winningResult) {
        return {...result};
      }

      if (arrLength === 0 || tmp[currentPhase][arrLength - 1]?.length === table.digitNumber) {
        const newValue = data[phaseState]?.winningResult?.[arrLength]?.charAt(0);
        if (newValue !== undefined) {
          tmp[currentPhase].push(newValue);
        }
      } else {
        const nextChar = data?.[phaseState]?.winningResult?.[arrLength - 1]?.charAt(tmp[currentPhase][arrLength - 1]?.length);
        if (nextChar !== undefined) {
          tmp[currentPhase][arrLength - 1] += nextChar;
        }
      }

      if (arrLength === table.totalSwingTime && tmp[currentPhase][arrLength - 1]?.length === table.digitNumber) {
        currentPhase = phase[phaseState + 1];
        tmpState = phaseState + 1;
      }

      setState(tmpState);
      return tmp;
    },
    [phaseState, phase, result, TableData],
  );

  useEffect(() => {
    if (dataResponse) {
      const obj = getResultByTime(calculateDifferenceTime(scheduleTime), dataResponse);
      setResult(obj.newResult);
      setState(obj.targetIndex);
      setSeconds(obj.seconds);
    }
  }, [scheduleTime, getResultByTime, dataResponse]);

  // set timer every unit seconds
  useEffect(() => {
    let timeout = null;
    if (!isDone) {
      let time = seconds > 0 ? seconds * 1000 : unit * 1000;
      timeout = setTimeout(() => {
        setResult(updateResult(dataResponse));
        setSeconds(0);
      }, time);
    }
    return () => clearTimeout(timeout);
  }, [result, isDone, updateResult, seconds, dataResponse]);

  useEffect(() => {
    if (targetDate === undefined) return;
    const [datePart, timePart] = targetDate?.split(" ");
    const [year, month, day] = datePart?.split("-");
    let [hour, minute] = timePart?.split(":");
    // check AM or PM
    let time = "ព្រឹក";
    if (hour > 12) {
      time = "ល្ងាច";
      hour -= 12;
    }
    setDisplayDate({
      hour: hour,
      minute: minute,
      year: year,
      month: convertToKhmerMonth(month),
      day: day,
      time: time,
    });
  }, [targetDate]);

  if (dataResponse === undefined) return null;
  return (
    <>
      <Center>
        <Table bg="white" whiteSpace="nowrap" px={0} borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid">
          <Thead bgColor="#c8aa88" color="#000">
            <Tr>
              {isLiveModule && (
                <Td py={{base: 2, md: 6, lg: 8, xl: 8}} px={{base: 2, md: 4, lg: 6, xl: 6}} colSpan={3} alignContent="center" fontSize={{base: "14px", md: "25px", lg: "30px", xl: "30px"}} fontFamily="Moul Light" textAlign="center">
                  {xl ? (
                    <>
                      <Text mb={{base: 2, md: 6, lg: 10, xl: 0}}>{t("livehistory.header", {...displayDate})}</Text>
                      នៅថ្ងៃទី<span style={{fontFamily: "georgiapro-condblack"}}> {displayDate.day} </span>ខែ {displayDate.month} ឆ្នាំ<span style={{fontFamily: "georgiapro-condblack"}}> {displayDate.year} </span>ម៉ោង
                      <span style={{fontFamily: "georgiapro-condblack"}}>
                        {" "}
                        {displayDate.hour} : {displayDate.minute}
                      </span>{" "}
                      {displayDate.time}
                    </>
                  ) : (
                    <Text>
                      {t("livehistory.header", {...displayDate})}
                      នៅថ្ងៃទី<span style={{fontFamily: "georgiapro-condblack"}}> {displayDate.day} </span>ខែ {displayDate.month} ឆ្នាំ<span style={{fontFamily: "georgiapro-condblack"}}> {displayDate.year} </span>ម៉ោង
                      <span style={{fontFamily: "georgiapro-condblack"}}>
                        {" "}
                        {displayDate.hour} : {displayDate.minute}
                      </span>{" "}
                      {displayDate.time}
                    </Text>
                  )}
                </Td>
              )}
            </Tr>
            <Tr fontSize={{base: "12px", sm: "20px", md: "25px", lg: "30px"}} fontFamily="Moul Light" height={{base: "", sm: "40px", md: "60px", lg: "80px"}} px={0}>
              <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" px={{base: 1, md: 6, lg: 6}}>
                {t("root.rankreward")}
              </Td>
              <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center">
                {t("root.rewardprice")}
              </Td>
              <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center">
                {t("root.result")}
              </Td>
            </Tr>
          </Thead>
          <Tbody fontFamily="Baphnom">
            {TableData.map((entry) => (
              <StyledTableRow key={entry.id}>
                <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" fontSize={{base: "12px", md: "20px", lg: "24px"}} p={0}>
                  {t("root.reward")} {entry.winningLevel}
                </Td>
                <Td borderWidth="2px" borderColor="#e0e0e0" borderStyle="solid" textAlign="center" fontSize={{base: "12px", md: "20px", lg: "24px"}} lineHeight={{base: "20px", md: "30px", lg: "40px"}} px={0} py="0">
                  {entry.winningName}
                  <Text fontSize={{base: "16px", md: "20px", lg: "24px"}}>{entry.winningPrice?.toLocaleString()} ៛</Text>
                </Td>
                <Td
                  borderWidth="0px"
                  borderColor="#e0e0e0"
                  borderStyle="solid"
                  textAlign="center"
                  p={0}
                  {...(isLiveModule
                    ? {
                        bgPos: "start center",
                        bgSize: "auto 100%",
                        bgRepeat: "no-repeat",
                        bgImage: toAbsoluteUrl(entry.winningLevel === 1 && result?.[entry.id] && result?.[entry.id][0].length === entry.digitNumber ? "/assets/firstprize.gif" : ""),
                      }
                    : {})}
                >
                  {((entry.winningLevel >= 2 && entry.id <= 17) || (entry.winningLevel >= 2 && entry.id >= 21)) && (
                    <SimpleGrid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(2, 1fr)", xl: "repeat(3, 1fr)"}}>
                      {[...Array(entry.totalSwingTime)].map((_, index) => {
                        const height = {base: "60px", md: "100px", lg: "120px"};
                        return (
                          <Box borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}} key={index}>
                            <Flex key={index} height={height} justifyContent="center" alignItems="center">
                              {[...Array(entry.digitNumber)].map((_, dIndex) => {
                                const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                                const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                                const width = {base: "20px", md: "30px", lg: "40px"};
                                const fontSize = {base: "26px", md: "40px", lg: "52px"};
                                return (
                                  <Box key={dIndex}>
                                    {isResultVisible ? (
                                      <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                        {char}
                                      </Text>
                                    ) : (
                                      <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                        -
                                      </Text>
                                    )}
                                  </Box>
                                );
                              })}
                            </Flex>
                          </Box>
                        );
                      })}
                      <Box borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}}></Box>
                    </SimpleGrid>
                  )}
                  {entry.totalSwingTime >= 2 && entry.id >= 18 && entry.id <= 19 && (
                    <SimpleGrid templateColumns={{base: "repeat(1, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(2, 1fr)", xl: "repeat(3, 1fr)"}}>
                      {[...Array(entry.totalSwingTime)].map((_, index) => {
                        const height = {base: "60px", md: "100px", lg: "120px"};
                        return (
                          <Box borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}} key={index}>
                            <Flex key={index} height={height} justifyContent="center" alignItems="center">
                              {[...Array(entry.digitNumber)].map((_, dIndex) => {
                                const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                                const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                                const width = {base: "20px", md: "30px", lg: "40px"};
                                const fontSize = {base: "26px", md: "40px", lg: "52px"};
                                return (
                                  <Box key={dIndex}>
                                    {isResultVisible ? (
                                      <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                        {char}
                                      </Text>
                                    ) : (
                                      <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                        -
                                      </Text>
                                    )}
                                  </Box>
                                );
                              })}
                            </Flex>
                          </Box>
                        );
                      })}
                      <Box borderWidth="1px" borderColor="#e0e0e0" borderStyle="solid" mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}}></Box>
                    </SimpleGrid>
                  )}
                  {entry.totalSwingTime <= 1 && entry.id >= 18 && entry.id <= 19 && (
                    <Box>
                      {[...Array(entry.totalSwingTime)].map((_, index) => {
                        const height = {base: "60px", md: "100px", lg: "120px"};
                        return (
                          <Box mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}} key={index}>
                            <Flex key={index} height={height} justifyContent="center" alignItems="center">
                              {[...Array(entry.digitNumber)].map((_, dIndex) => {
                                const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                                const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                                const width = {base: "20px", md: "30px", lg: "40px"};
                                const fontSize = {base: "26px", md: "40px", lg: "52px"};
                                return (
                                  <Box key={dIndex}>
                                    {isResultVisible ? (
                                      <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                        {char}
                                      </Text>
                                    ) : (
                                      <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                        -
                                      </Text>
                                    )}
                                  </Box>
                                );
                              })}
                            </Flex>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  {entry.winningLevel === 1 && (
                    <Box>
                      {[...Array(entry.totalSwingTime)].map((_, index) => {
                        const height = {base: "60px", md: "100px", lg: "120px"};
                        return (
                          <Box mb={{base: "-0.6px", md: "-1px", lg: "-0.4px", xl: "-1px"}} key={index}>
                            <Flex key={index} height={height} justifyContent="center" alignItems="center">
                              {[...Array(entry.digitNumber)].map((_, dIndex) => {
                                const char = result?.[entry.id]?.[index]?.charAt(dIndex);
                                const isResultVisible = result?.[entry.id]?.[index] !== undefined && char !== "";
                                const width = {base: "26px", md: "40px", lg: "65px"};
                                const fontSize = {base: "34px", md: "60px", lg: "82px"};
                                return (
                                  <Box key={dIndex}>
                                    {isResultVisible ? (
                                      <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                        {char}
                                      </Text>
                                    ) : (
                                      <Text fontFamily="georgiapro-condblack" fontSize={fontSize} width={width} color="#000">
                                        -
                                      </Text>
                                    )}
                                  </Box>
                                );
                              })}
                            </Flex>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Td>
              </StyledTableRow>
            ))}
          </Tbody>
        </Table>
      </Center>
    </>
  );
}
