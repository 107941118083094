import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "km",
    lng: "km",
    resources: {
      en: {
        translation: {
          language: "English",
          menubar: {
            homepage: "Homepage",
            history: "History Result",
            guidance: "Guidance",
            legality: "Legality",
            about: "About Company",
            corporate: "Corporate Activity",
          },
          // root
          root: {
            notfake: "The prize is real and valuable",
            rankreward: "Ranking",
            rewardprice: "Prize",
            result: "Lottery Result",
            reward: "Reward No ",
          },
          table: {
            reward2D: "2D Lucky Draw",
            reward3D: "3D Lucky Draw",
            reward4D: "4D Lucky Draw",
            reward5D: "5D Lucky Draw",
            rewardBronze: "Big Trophy 'Merit' 6D",
            rewardSilver: "Big Trophy 'Money' 6D",
            rewardGold: "Big Trophy 'Gold' 6D",
          },
          // about company
          aboutcompany: {
            about: {
              header: "*** About the Company ***",
              title: "Cambodia has experienced significant growth in all sectors, and there are various types of state-owned enterprises operating in many areas such as product sales, food and beverage, service provision, telecommunications services, transportation services, etc. These indicate that business activities in Cambodia are highly competitive and diverse, providing various benefits to customers. According to the World Bank, Cambodia has been recognized for its good governance, stability, and human resources. Recently, Vietnamese enterprises have also participated in providing quality products to Cambodian consumers, with a focus on the following areas: food safety, quality standards, and technology. In recent years, Vietnamese enterprises have been welcomed by customers because they see that they have experience and understand the needs of customers well.",
              option1: "1. Village enterprises are prioritized, which customers find difficult to access.",
              option2: "2. Village enterprises need to be upgraded, requiring small and large investments, not just large investments.",
              option3: "3. Village enterprises have potential in managing purchasing, sales, and debt collection.",
              conclusion: "Based on the above reasons, it is necessary to help customers be more familiar with village enterprises and see them as a real option. Vietnamese enterprises have been growing rapidly in the Cambodian market with a 100% satisfaction rate.",
              finally: "Vietnamese enterprises have been established by Cambodian experts to give Cambodian people true opportunities to participate in a single market, easily accessible to people of all walks of life. Vietnamese enterprises have emerged in various forms of Cambodian traditional wisdom and want to see changes in leadership from traditional to modern ways. The Vietnamese enterprise group will provide quality services to Cambodian people with many incentives at all times and occasions.",
            },
            vision: {
              header: "*** Vision ***",
              content: "To become a leading market leader in the Cambodian market, focusing on research, integrity, and human resources.",
            },
            mission: {
              header: "*** Mission ***",
              content: "To share the largest market share in Cambodia through ASEAN integration by 2025.",
            },
            advantage: {
              header: "*** Advantages ***",
              option1: "1. Legal Compliance: Focus on legal compliance for all business operations.",
              option2: "2. Social Responsibility: Part of the company's profits will be contributed to social assistance programs.",
              option3: "3. Governance: The company has a transparent system, values, principles, and effective management leadership.",
              option4: "4. Product: High-quality products, diverse products, guaranteed quality, and competitive prices.",
              option5: "5. System: Efficient work premises, productivity, cost reduction, and increased revenue.",
              option6: "6. Network: Expansive market network, able to adapt flexibly to market changes.",
            },
            culture: {
              header: "*** Culture ***",
              integrity: {
                title: "Integrity: We conduct business with integrity by:",
                option1: "❶ Being honest and ethical",
                option2: "❷ Valuing moral and ethical principles",
                option3: "❸ Prioritizing moral and human values",
              },
              accountability: "High Accountability: We hold ourselves accountable by taking responsibility promptly, responding to issues timely, enhancing teamwork, avoiding blame-shifting, refraining from creating excuses to avoid accountability.",
              service: "Good Customer Service: Providing satisfaction, being humble, avoiding arrogance, providing information promptly, spending time explaining about the company's services, responding to needs promptly, understanding customer situations, helping to find solutions, showing empathy, and listening to customer feedback.",
              professional: "Be Professional: Maintaining professional conduct, being punctual, focusing on work, using appropriate language, being punctual, considering the impact of actions, taking responsibility, delivering work results, professionalism is the guideline for our work.",
              right_thing: "Do Things Right the First Time: We recognize that we will waste valuable time, reputation, resources, core values, and customer trust if we do not do things right the first time. We commit not to compromise and will strive to excel in our work from the outset. If we fail, we will learn from our mistakes and try again.",
              focus: "Stay Focused on One Thing: We allocate more time to focus on one task than taking multiple tasks. We believe that focusing our attention on one task at a time will enable us to perform that task well and efficiently.",
              learning: "Keep Learning: The company encourages everyone to continue learning because if we want more, we will receive more. We strongly encourage: ❶ learning new things, ❷ learning from others, ❸ learning from past experiences, and ❹ learning to read more.",
              believe: {
                title: "Believe - Anything is Possible:",
                option1: "❶ Determination: Determination is the key to success because we will never achieve anything if we are not determined to focus on it. We are determined to focus on the company's goals, focus on the company's products, focus on the company's work system, focus on teamwork, focus on ourselves, and believe that 'Anything is Possible' and 'We can do it'.",
                option2: "❷ Optimism: Many people give up on their goals or dreams prematurely because they have been disappointed by their past achievements. We believe that 'Do not give up' and 'We can do this' are essential beliefs that will lead to success. We believe that 'Failure is not a waste' and 'We will try again'.",
              },
              teamwork: "Teamwork: ❶ Set clear team goals ❷ Share work evenly ❸ Trust each other ❹ Build good relationships ❺ Listen to each other ❻ Support each other ❼ Resolve conflicts peacefully ❽ Help each other grow ❾ Celebrate successes together.",
              gratitude: {
                title: "Gratitude: Gratitude is the key to many successes in life. If we are grateful for what we have when we have it, we will receive many blessings. Therefore, we are grateful for:",
                option1: "• Gratitude for the parents who gave birth and raised us from the beginning.",
                option2: "• Gratitude for the country that has opportunities that can make us successful in life.",
                option3: "• Gratitude for the company that provides us with jobs, income, additional training opportunities that make our lives prosperous and our people happy.",
              },
            },
          },
          // guidance
          guidance: {
            title: {
              header: "Report and Management Guidelines",
              title: "Product Evaluation Report",
              brand: "“Pekarumdo”",
            },
            part1: {
              title: "Part 1: Overview of Report and Guidelines",
              content: "This report and management guidelines for the product evaluation report of 'Pekarumdo' have been prepared for the additional fulfillment of governmental regulations for the 'Pekarumdo' product. In this regard, governmental management must also be applied appropriately for this product. In terms of understanding the content of this report and guidelines, it should be considered as essential for employees, supervisors, distributors, and consumers of the 'Pekarumdo' product.",
            },
            part2: {
              title: "Part 2: Introduction",
              option1: "1. \"Main Category\" focuses on the main types of 'Pekarumdo' products with 6 main categories for customers to choose from.",
              option2: "2. \"Bulk Purchase\" focuses on bulk purchases of 'Pekarumdo' products, with each bulk containing 1,000 items numbered from 000000 to 999999, totaling 1,000,000 (one million) items per barrel.",
              option3: "3. \"Special Instructions\" on 'Pekarumdo' products are essential to protect consumers from minor hazards.",
              option4: "4. \"Lottery Ticket Number\" must show that the numbers match the results of the 'Pekarumdo' lottery published through wingolotto.com, Telegram Channel, and the company's Facebook Page.",
              option5: "5. \"Retailers\" are sellers or distributor companies authorized to sell 'Pekarumdo' products.",
              option6: "6. \"Verification Code\" is a unique code size located at the bottom of the label of each product, which retailers can use to verify and clarify the level of prize money for the winning lottery tickets. The verification code for retailers must be specified in the 'Pekarumdo' product documentation and report and management guidelines.",
              option7: "7. \"Main Category Numbers\" for 'Main Category' include, for example: 123456, 888888, 000999, 555555, 000229.",
              option8: '8. "Main Category Label" is sized W125mm x H58mm for standard barcode types.',
            },
            part3: {
              title: "Part 3: Product Description",
            },
            part4: {
              title: "Part 4: Determining the Winning Number for Lottery",
              content: "The number identified as the winning number for the lottery must have matching numbers in the sequence as listed below, counting from left to right. Players of the lottery can select multiple sets of winning numbers for a single lottery ticket. The prize money for the winning numbers is as follows:",
              option1: "1. Winning Ticket Number 12 (2D): The last 2 digits of the ticket number at the back of the lottery ticket must match the last 2 digits of the winning number 12 in sequence, and it can be matched in two different ways. For example: if the winning numbers are 01 and 12 and your ticket number ends with 12, you will win the lottery number 12, receiving a prize of 10,000 Riels.",
              option2: "2. Winning Ticket Number 11 (2D): The last 2 digits of the ticket number at the back of the lottery ticket must match the last 2 digits of the winning number 11 in sequence, and it can be matched in one way. For example: if the winning numbers are 22 and your ticket number ends with 22, you will win the lottery number 11, receiving a prize of 20,000 Riels.",
              option3: "3. Winning Ticket Number 10 (3D): The last 3 digits of the ticket number at the back of the lottery ticket must match the last 3 digits of the winning number 10 in sequence, and it can be matched in two different ways. For example: if the winning numbers are 331 and 332 and your ticket number ends with 332, you will win the lottery number 10, receiving a prize of 50,000 Riels.",
              option4: "4. Winning Ticket Number 9 (4D): The last 4 digits of the ticket number at the back of the lottery ticket must match the last 4 digits of the winning number 9 in sequence, and it can be matched in ten different ways. For example: if the winning numbers are 4441, 4442, 4443, 4444, 4445, 4446, 4447, 7778, 4449, 4450 and your ticket number ends with 4442, you will win the lottery number 9, receiving a prize of 100,000 Riels.",
              option5: "5. Winning Ticket Number 8 (4D): The last 4 digits of the ticket number at the back of the lottery ticket must match the last 4 digits of the winning number 8 in sequence, and it can be matched in two different ways. For example: if the winning numbers are 5551, 5552 and your ticket number ends with 5552, you will win the lottery number 8, receiving a prize of 200,000 Riels.",
              option6: "6. Winning Ticket Number 7 (4D): The last 4 digits of the ticket number at the back of the lottery ticket must match the last 4 digits of the winning number 7 in sequence, and it can be matched in two different ways. For example: if the winning numbers are 5555 and your ticket number ends with 5555, you will win the lottery number 7, receiving a prize of 500,000 Riels.",
              option7: "7. Winning Ticket Number 6 (5D): The last 5 digits of the ticket number at the back of the lottery ticket must match the last 5 digits of the winning number 6 in sequence, and it can be matched in six different ways. For example: if the winning numbers are 66641, 66642, 66643, 66644, 66645, 66646 and your ticket number ends with 66645, you will win the lottery number 6, receiving a prize of 1,000,000 Riels.",
              option8: "8. Winning Ticket Number 5 (5D): The last 5 digits of the ticket number at the back of the lottery ticket must match the last 5 digits of the winning number 5 in sequence, and it can be matched in three different ways. For example: if the winning numbers are 66651, 66652, 66653 and your ticket number ends with 66651, you will win the lottery number 5, receiving a prize of 2,000,000 Riels.",
              option9: "9. Winning Ticket Number 4 (5D): The last 5 digits of the ticket number at the back of the lottery ticket must match the last 5 digits of the winning number 4 in sequence, and it can be matched in one way. For example: if the winning number is 66666 and your ticket number ends with 66666, you will win the lottery number 4, receiving a prize of 5,000,000 Riels.",
              option10: "10. Jackpot 3rd Prize (6D): The last 6 digits of the ticket number at the back of the lottery ticket must match the last 6 digits of the winning number 3 in sequence, and it can be matched in four different ways. For example: if the winning numbers are 777771, 777772, 777773, 777774 and your ticket number ends with 777773, you will win the jackpot 3rd prize, receiving a prize of 10,000,000 Riels.",
              option11: "11. Jackpot 2nd Prize (6D): The last 6 digits of the ticket number at the back of the lottery ticket must match the last 6 digits of the winning number 2 in sequence, and it can be matched in three different ways. For example: if the winning numbers are 888881, 888882, 888883 and your ticket number ends with 888881, you will win the jackpot 2nd prize, receiving a prize of 20,000,000 Riels.",
              option12: "12. Jackpot 1st Prize (6D): The last 6 digits of the ticket number at the back of the lottery ticket must match the last 6 digits of the winning number 1 in sequence, and it can be matched in one way. For example: if the winning number is 999999 and your ticket number ends with 999999, you will win the jackpot 1st prize, receiving a prize of 100,000,000 Riels.",
            },
            part5: {
              title: "Part 5: Prize and Jackpot Levels for Lottery",
              option1: "Prize 'Close Number': Each close number has a value of 2,000 Riels.",
              option2: "Each share has 1,000,000 shares and a total value of 2,000,000,000 Riels.",
              option3: "The total prize pool is 1,060,000,000 Riels.",
              option4: "The total number of lottery tickets to be issued is 33,408 tickets.",
              option5: "The jackpot prize amount is 100,000,000 Riels and must be claimed by the original group by connecting the initial ID card.",
              option6: "The prize level for the 'Close Number' ticket is superior due to no prior arrangement of results.",
              option7: "Lottery tickets eligible for prizes are valid for 30 days from the date of result announcement.",
              option8: "Betting rules: Lottery tickets must be kept intact, complete, undamaged, unsoiled, and unaltered.",
              option9: "Customers must carefully check their lottery tickets to confirm the winning numbers. Customers cannot request to open a winning lottery ticket if the close number arrangement has already been damaged.",
              option10: "Lottery prize results are announced at 6:30 PM every Tuesday and Friday at the company's headquarters.",
              option11: "Prizes are only awarded once per valid ticket either in cash or by bank transfer.",
            },
            part6: {
              title: "Part 6: How to Play",
              option1: "- Simply select a lottery ticket with 6 digits, comprising all numbers from 000000 to 999999, and purchase it from the agency. For example: 654321, 888888, 000999. Then, you need to keep the ticket containing the lucky numbers for validation when the lottery results are announced and prizes are claimed.",
              option2: "- The lottery results will be drawn based on numbers generated by the computer system and must be announced on the company's website. The jackpot ticket will be determined by drawing one from 36 balls.",
              option3: "- Each ticket can have only 36 lucky numbers and may also have 36 backup lucky numbers, depending on the set level by the lottery company.",
              option4: "- Note: All lucky numbers to be drawn and lottery results must be in numerical order.",
            },
            part7: {
              title: "Part 7: Mandatory Rules for Lottery Validation",
              option1: "1. Each winning number must be clearly and legibly printed and readable in its entirety.",
              option2: "2. The lottery ticket must be intact, not torn, damaged, altered, or modified in any way.",
              option3: "3. The entire lottery ticket or any part of it must not be counterfeit. A counterfeit ticket invalidates the prize claim and may result in legal action or penalties against any party involved.",
              option4: "4. The lottery ticket must be issued by the lottery company in accordance with the prescribed procedure.",
              option5: "5. A lottery ticket that has been lost, stolen, or excluded from the lottery list cannot be reissued, and any attempt to exchange prizes for such tickets is invalid.",
              option6: "6. The serial numbers on lottery tickets must be authenticated using the appropriate lettering for identification purposes, and must be accompanied by an emblem or logo within the documents issued by the lottery company.",
              option7: "7. Each title must be accompanied by a logo within the documents issued by the lottery company.",
              option8: "8. The serial numbers on lottery tickets must be authenticated using the appropriate lettering for identification purposes, and must be accompanied by an emblem or logo within the documents issued by the lottery company.",
              option9: "9. Lottery tickets must be kept until the end of the prescribed period for additional verification by the lottery management and as an additional security measure of the lottery company.",
            },
          },
          // history
          history: {
            header: "Results of the lottery draw on the {{day}}th of {{month}}, {{year}}",
          },
          // timecount
          timecount: {
            showtime: "The result will be released at {{hour}}, {{day}} {{month}}, {{year}}",
            title: "Remaining Time",
            time: {day: "day", hour: "hour", minute: "min", second: "sec"},
          },
        },
      },
      km: {
        translation: {
          language: "ភាសាខ្មែរ",
          menubar: {
            homepage: "ទំព័រដើម",
            history: "ប្រវត្តិឆ្នោតចេញ",
            guidance: "សេចក្ដីណែនាំ",
            legality: "ភាពស្របច្បាប់",
            about: "អំពីក្រុមហ៊ុន",
            corporate: "សកម្មភាពក្រុមហ៊ុន",
          },
          // root
          root: {
            notfake: "រង្វាន់មានពិត ឥតក្លែងបន្លំ",
            rankreward: "កម្រិតរង្វាន់",
            rewardprice: "ប្រាក់រង្វាន់",
            result: "លទ្ធផលឆ្នោត",
            reward: "រង្វាន់ទី ",
          },
          // table
          table: {
            reward2D: "រង្វាន់លួងចិត្ត 2D",
            reward3D: "រង្វាន់លួងចិត្ត 3D",
            reward4D: "រង្វាន់លួងចិត្ត 4D",
            reward5D: "រង្វាន់លួងចិត្ត 5D",
            rewardBronze: 'រង្វាន់ធំ "សំរិទ្ធ " 6D',
            rewardSilver: 'រង្វាន់ធំ "ប្រាក់ " 6D',
            rewardGold: 'រង្វាន់ធំ "មាស " 6D',
          },
          // about company
          aboutcompany: {
            about: {
              header: "*** អំពីក្រុមហ៊ុន ***",
              title1: `សង្គមកម្ពុជាមានការរីកចម្រើនគ្រប់វិស័យ ហើយប្រជាពលរដ្ឋកំពុងប្រកបមុខរបរច្រើនប្រភេទផ្សេងៗគ្នាដូចជាការដាំដុះបន្លែនិងផ្លែឈើ ការផលិតនិងកែឆ្នៃ ការលក់ដូរផលិតផល ការលក់ដូរអាហារនិងភេសជ្ជៈ ការផ្តល់សេវាកម្មចម្រុះ សេវាកម្មហិរញ្ញវត្ថុ និងឆ្នោតផ្សងសំណាង។ល។ ទាំងនេះបញ្ជាក់ថានៅប្រទេសកម្ពុជាមានឱកាសធុរកិច្ចច្រើន និងមានលក្ខណៈសម្បូរបែប ទន្ទឹមនឹងនេះការផ្តល់ឱកាសផ្សងសំណាងដល់អតិថិជនក៏មានច្រើនរូបភាពផងដែរ។ ស្របនឹងវឌ្ឍនភាពនៃប្រទេសកម្ពុជា ក្រុមហ៊ុនវីនហ្គោ អ៊ីនវេសម៉ិន ឯ.ក ក៏ចូលរួមចំណែកផ្តល់ឱកាសផ្សងសំណាងឥតក្លែងបន្លំជូនប្រជាពលរដ្ឋកម្ពុជាផងដែរ តាមយៈឆ្នោតផ្សងសំណាងវីនហ្គោ ដែលត្រូវបានរចនាឡើងយ៉ាងល្អឯកទាំងផលិតផលនិងប្រព័ន្ធគ្រប់គ្រង ដោយឈរលើគោលការណ៍៖ តម្លាភាព ~ ទំនុកចិត្ត ~ មនុស្សធម៌។`,
              title2: `មកដល់ពេលនេះ ឆ្នោតផ្សងសំណាងនៅក្នុងស្រុកមិនទាន់ពេញនិយមពីប្រជាពលរដ្ឋឡើយ ដោយសារនៅមិនទាន់មានជំនឿទៅលើភាពស្របច្បាប់ និងយល់ឃើញថាឆ្នោតក្នុងស្រុកមានភាពសាំញ៉ាំក្នុងការធ្វើប្រតិបត្តិការណ៍ទិញលក់ និង ទូទាត់រង្វាន់ ហើយគិតថាឆ្នោតក្នុងស្រុកបានរៀបចំលទ្ធផលទុកជាមុន ដែលអតិថិជនមិនងាយត្រូវរង្វាន់ធំ។ ដូច្នេះហើយ អតិថិជននៅតែបន្តលេងឆ្នោតកន្ទុយលេខវៀតណាម និងឆ្នោតថៃ ដែលជាប្រភេទល្បែងស៊ីសងខុសច្បាប់ ទោះបីមានការរឹតត្បឹតពីរាជរដ្ឋាភិបាលកម្ពុជាក៏ដោយ។ 
               ដើម្បីឆ្លើយតបទៅនឹងបញ្ហាខាងលើ ឆ្នោតវីនហ្គោបានចូលរួមបម្រើ និងបំពេញភាពខ្វះចន្លោះក្នុងវិស័យឆ្នោតផ្សងសំណាងនេះឲ្យខានតែបាន ដោយយកគោលការណ៍ឥតក្លែងបន្លំ១០០%។`,
              title3: `ឆ្នោតវីនហ្គោត្រូវបានបង្កើតឡើងដោយពាណិជ្ជករខ្មែរ ដើម្បីប្រជាជនខ្មែរឲ្យមានឱកាសពិតប្រាកដក្នុងការផ្សងសំណាងមួយ ងាយត្រូវ ងាយបានដល់ដៃមនុស្សគ្រប់រូប។ ឆ្នោតវីនហ្គោរចនាឡើងក្នុងទម្រង់បរិបទនៃវប្បធម៌ខ្មែរ យកអត្តសញ្ញាណខ្មែរ មានបេះដូងជាខ្មែរ ហើយមានគោលដៅផ្លាស់ប្តូរផ្នត់គំនិត ពីការចាត់ទុកឆ្នោតជាល្បែងស៊ីសង មកជាការផ្សងសំណាងជាមួយឆ្នោតវិញម្តង។ ក្រុមហ៊ុនឆ្នោតវីនហ្គោនឹងផ្តល់ឱកាសមហាសំណាងជូនប្រជាជនខ្មែរ ជាមួយរង្វាន់យ៉ាងច្រើនសន្ធឹកសន្ធាប់ គ្រប់ពេលនិងគ្រប់រដូវកាល។`,
            },
            vision: {
              header: "*** ទស្សនៈវិស័យ ***",
              content: "ធ្វើជាអ្នកនាំមុខទីផ្សារឆ្នោតផ្សងសំណាងនៅព្រះរាជាណាចក្រកម្ពុជា ដោយឈរលើគោលការណ៍ តម្លាភាព ~ ទំនុកចិត្ត ~ មនុស្សធម៌។",
            },
            mission: {
              header: "*** បេសកកម្ម ***",
              content1: "ក្តោបក្តាប់ចំណែកទីផ្សារឆ្នោតផ្សងសំណាងធំជាងគេនៅកម្ពុជាតាមរយៈ",
              content2: "ដៃគូចែកចាយឯករាជ្យ",
              content3: " នៅទូទាំងប្រទេសត្រឹមបំណាច់ឆ្នាំ ២០២៥។",
            },
            advantage: {
              header: "*** គុណតម្លៃ ***",
              option1: "1.	ភាពស្របច្បាប់ (Legal Compliance)៖ឈរលើគោលការណ៍ស្របច្បាប់សម្រាប់គ្រប់វិសាលភាពនៃធុរកិច្ច។",
              option2: "2.	ទំនួលខុសត្រូវសង្គម (Social Responsibility)៖ មួយផ្នែកនៃប្រាក់ចំណេញរបស់ក្រុមហ៊ុននឹងចូលរួមជាមូលនិធិសប្បុរសសម្រាប់ជួយជនពិការ។",
              option3: `3.	អភិបាលកិច្ច (Governance)៖ ក្រុមហ៊ុនមានចក្ខុវិស័យឆ្ងាយ មានបេសកកម្មច្បាស់ មានគុណតម្លៃ មានវប្បធម៌ ហើយរចនាសម្ព័នដឹកនាំនៃគណៈគ្រប់គ្រង និងបុគ្គលិកក្រុមហ៊ុនមានគុណវុឌ្ឍិ។`,
              option4: "4.	ផលិតផល (Product)៖ ការរចនាល្អឯក ឳកាសឈ្នះច្រើន រង្វាន់មានច្រើន រង្វាន់មានពិត ឥតមានការក្លែងបន្លំ។",
              option5: "5.	ប្រព័ន្ធគ្រប់គ្រង (Platform)៖ បទដ្ឋានការងារមានស្តង់ដា ផ្តល់ប្រសិទ្ធភាពការងារខ្ពស់ ជួយកាត់បន្ថយចំណាយ និងបង្កើនប្រាក់ចំណូល។",
              option6: "6.	ធនធានមនុស្ស (People)៖ ការអភិវឌ្ឍសមត្ថភាពបុគ្គលិក និងភ្នាក់ងារឱ្យមានចំណេះជំនាញ ជាកិច្ចការអទិភាពរបស់វីនហ្គោដើម្បីជម្រុញធុរកិច្ចឱ្យរីកចម្រើន និងមានស្ថេរភាព។",
            },
            culture: {
              header: "*** វប្បធម៌ ***",
              integrity: {
                title: "សេចក្តីសុច្ចរិត (Integrity)៖ យើងប្រតិបត្តិកិច្ចការធុរកិច្ចដោយប្រកាន់ខ្ជាប់៖",
                option1: "❶ ស្មោះត្រង់ សច្ចៈធម៌",
                option2: "❷ ថ្លៃថ្នូរ សុជីវធម៌ សីលធម៌",
                option3: "❸ តម្លាភាព យុត្តិធម៌ មនុស្សធម៌ ។",
              },
              accountability: {
                title: "ទំនួលខុសត្រូវខ្ពស់ (Accountability)៖",
                content: "យើងដាក់ការទទួលខុសត្រូវជាកាតព្វកិច្ច ឆ្លើយតបបញ្ហាទាន់ពេលវេលា បង្កើនការជឿជាក់ជាមួយមិត្តរួមការងារ មិនរុញច្រានបញ្ហាដាក់គ្នា មិនបង្កើតលេសដើម្បីគេចវេសចេញពីការទទួលខុសត្រូវ។",
              },
              service: {title: "សេវាបម្រើអតិថិជនល្អ (Good customer service)៖", option1: "❶ ផ្តល់ការស្វាគមន៍", option2: " ❷ ដាក់ខ្លួនអោយទាប", option3: " ❸ គោរពអោនលំទោន", option4: " ❹ ផ្តល់ពត៌មានគ្រប់គ្រាន់", option5: " ❺ ចំណាយពេលពន្យល់ពីបទដ្ឋានប្រតិបត្តការណ៌", option6: " ❻ ឆ្លើយតបតម្រូវការទាន់ពេលវេលា", option7: " ❼ ព្យាយាមស្វែងយល់ពីស្ថានភាពពិតរបស់អតិថិជន", option8: " ❽ ជួយស្វែងរកដំណោះស្រាយនៃបញ្ហា", option9: " ❾ ឧស្សាហ៍ចុះសួរសុខទុក្ខ", option10: " ❿ តាមដានអតិថិជនឱ្យបានជាប់លាប់។"},
              professional: {title: "មានវិជ្ជាជីវៈ (Be Professional)៖", option1: " ❶ ការស្លៀកពាក់សមរម្យ", option2: " ❷ ការផ្ចង់អារម្មណ៍លើការងារ", option3: " ❸ ការប្រើភាសាថ្លៃថ្នូរមិននិយាយដើមគ្នា នៅកន្លែងធ្វើការ", option4: " ❹ ការគោរពពេលវេលាធ្វើការ", option5: " ❺ ការគិតវិជ្ជមាន", option6: " ❻ ការទទួលខុសត្រូវ", option7: " ❼ សម្រេចលទ្ធផលការងារ ជាគោលការណ៍នៃវិជ្ជាជីវៈការងាររបស់យើងនៅកន្លែងធ្វើការ។"},
              right_thing: {title: "តាំងចិត្តធ្វើអោយល្អតាំងពីដំបូង (Do thing right the first time)៖ ", content: "យើងទទួលស្គាល់ថា យើងនឹងបាត់បង់ពេលវេលាដ៏មានតម្លៃ កេរ្តិ៍ឈ្មោះ វត្ថុធាតុដើម ធនធានហិរញ្ញវត្ថុ ហើយថែមទាំងអាចឈានទៅដល់ការបរាជ័យក្នុងបេសកម្មទៀតផងបើសិនជាយើងមិនបាន ធ្វើការងារអោយបានល្អតាំងពីដំបូង ទោះបីជាយើងបានព្យាយាមប្រឹងប្រែងអស់ពីសមត្ថភាពខ្លួនក៏ដោយយើងប្រកាន់ខ្ជាប់គោលកាណ៍ “EXCELLENCE“ ដែលបណ្តុះគំនិត ទំលាប់ និងការអនុវត្តការងារល្អត្រឹមត្រូវតាំងពីពេលដំបូងចៀសវាងមានកំហុសហើយត្រូវ ធ្វើសារឡើងវិញម្តងហើយម្តងទៀត។"},
              focus: {title: "ផ្តោតអារម្មណ៍លើកិច្ចការតែមួយ (Stay Focus on one thing)៖", content: " យើងចំណាយពេលឱ្យច្រើនធ្វើកិច្ចការតែមួយជាជាងចាប់យកកិច្ចការ ច្រើនតែចំណាយពេលធ្វើតិចតួច។ យើងជឿជាក់ថា អោយតែផ្តោតអារម្មណ៍ធ្វើការងារជាចម្បងយើងនឹងអាចធ្វើការងារនោះបានយ៉ាងល្អ និងយូរអង្វែង។"},
              learning: {title: "រៀនសូត្របន្ថែម Keep Learning៖ ក្រុមហ៊ុនលើកទឹកចិត្តអោយមិត្តរួមការងារទាំងអស់បន្តរៀនសូត្របន្ថែមជានិច្ចព្រោះថា បើយើងចេះកាន់តែច្រើន យើងក៏នឹងទទួលបានផលចំណូលកាន់តែច្រើនដូចគ្នា។ យើងបើកចិត្តទូលាយ៖", option1: " ❶ រៀនសូត្រនូវអ្វីដែលថ្មី", option2: " ❷ រៀនស្តាប់ពីអ្នកដទៃ", option3: " ❸ រៀនអង្កេតសកម្មភាពជុំវិញខ្លួន", option4: " ❹ រៀនអានឱ្យបានច្រើន។"},
              believe: {
                title: "ជឿជាក់ថាអាចធ្វើបាន (Believe - Anything is Possible)៖ ",
                option1: "❶ ជំនឿ៖ ជំនឿជាឫសគល់នៃលទ្ធផល ព្រោះថាយើងនឹងមិនទៅធ្វើរឿងអ្វីមួយឡើយប្រសិនបើយើងមិនជឿជាក់ទៅលើរឿងនុះ។ យើងផ្តល់ជំនឿចិត្តលើក្រុមហ៊ុន ជឿជាក់លើផលិតផលរបស់ក្រុមហ៊ុន ជឿជាក់លើប្រព័ន្ធការងារ ជឿជាក់លើក្រុមការងារ និងជាពិសេសជឿជាក់លើខ្លួនឯង និងជឿថា៖ “រឿងអ្វីក៏នឹងអាចធ្វើបានដែរ” ហើយ “យើងអាចធ្វើវាបាន” ។",
                option2: "❷ តស៊ូ៖មនុស្សជាច្រើនបានបោះបង់ចោលនូវគោលដៅឬក្តីស្រមៃរបស់ខ្លួនកណ្ដាលទីដោយហេតុផលផ្សេងៗគ្នា មានតែមនុស្សមួយចំនួនតូចប៉ុណ្ណោះដែលមានការតាំងចិត្ថខ្ពស់ ប្រឹងប្រែងធ្វើការអស់ពីកំលាំងកាយចិត្ត ស៊ូទ្រាំនឹងការលំបាក មិនចុះចាញ់នឹងឧបសគ្គរារាំង បានទទួលលទ្ធផលតបស្នងដ៏ប្រសើ។ យើងតាំងចិត្តថា “មិនបោះបង់” ហើយ “នឹងតស៊ូធ្វើកិច្ចការនេះរហូតបានសម្រេច”។",
              },
              teamwork: {title: "ការងារជាក្រុម (Teamwork)៖", option1: " ❶ ដាក់គោលដៅក្រុមច្បាស់លាស់", option2: " ❷ ចែករំលែកការងារឱ្យគ្នា", option3: " ❸ ទុកចិត្តគ្នា", option4: " ❹ រក្សាទំនាក់ទំនងល្អ", option5: " ❺ ស្តាប់គ្នាទៅវិញទៅមក", option6: " ❻ គោរពគ្នា", option7: " ❼ អត់អោនអោយគ្នា", option8: " ❽ ដោះស្រាយបញ្ហាជាលក្ខណៈមិត្តភាព", option9: " ❾ ជួយគ្នាទៅវិញទៅមក", option10: " ❿ សាទរភាពជោគជ័យជាមួយគ្នា ។"},
              gratitude: {
                title: "ការដឹងគុណ (Gratitude)៖",
                content: " ការដឹងគុណគឺជាឫសគល់របស់បុគ្គលជោគជ័យជាច្រើននៅលើពិភពលោក។ បើចេះដឹងគុណនូវអ្វីដែលយើងកំពុងមាននាពេលបច្ចុប្បន្ន យើងនឹងទទួលបានផលតបស្នងជាច្រើននាពេលអនាគត់។ យ៉ាងហោចណាស់យើងដឹងគុណនូវរឿងបីយ៉ាងគឺ ៖",
                option1: "ដឹងគុណលោកឪពុកអ្នកម្តាយដែលបានផ្តល់កំណើតនិងចិញ្ចឹមបីបាច់យើងតាំងពីដើមរៀងមក។",
                option2: "ដឹងគុណប្រទេសជាតិដែលមានសុខសន្តិភាពអាចឱ្យអ្នកទទួលបានការអប់រំ មានចំណេះជំនាញដូចសព្វថ្ងៃ។",
                option3: "ដឹងគុណក្រុមហ៊ុនដែលផ្តល់ឱកាសឱ្យយើងបានធ្វើការ ឱ្យយើងទទួលបានប្រាក់ចំណូល ឱ្យយើងទទួលបានការបង្ហាត់បង្រៀនជំនាញបន្ថែម ដែលធ្វើអោយជីវភាពយើងកាន់តែប្រសើ និងអនាគត់អ្នកកាន់តែភ្លឺស្វាង ។",
              },
            },
            manager: {
              title: "***ក្រុមប្រឹក្សាភិបាល***",
              option1: "1.	លោក អេង លីឃ័ង៖ ប្រធានក្រុមប្រឹក្សាភិបាល",
              option2: "2.	លោក លឹម សេងជ្រុង៖ សមាជិកក្រុមប្រឹក្សាភិបាល និង នាយកប្រតិបត្តិ",
              option3: "3.	លោក ធី ប៊ុនធឿន៖ សមាជិកក្រុមប្រឹក្សាភិបាល និង នាយកទីផ្សារ",
            },
          },
          // guidance
          guidance: {
            title: {
              header: "គោលការណ៍ និងវិធានគ្រប់គ្រង",
              title: "ឆ្នោតសន្លឹកផ្សងសំណាង",
              brand: "“ ផ្ការំដួល ”",
            },
            part1: {
              title: "ផ្នែកទី ១ ៖ គោលបំណងរបស់គោលការណ៍ណែនាំ",
              content: "គោលការណ៍ណែនាំ និងវិធានគ្រប់គ្រងឆ្នោតសន្លឹកនេះ ត្រូវបានរៀបចំឡើងសម្រាប់ឆ្នោតសន្លឹក “ផ្ការំដួល” ហើយគឺជាគោលការណ៍ និងវិធានដែលបំពេញបន្ថែមវិធានរដ្ឋបាលនៃឆ្នោតសន្លឹក “ផ្ការំដួល”។ ក្នុងន័យនេះ វិធានរដ្ឋបាលក៏ត្រូវយកមកអនុវត្តចំពោះឆ្នោតសន្លឹកនេះផងដែរ។ ក្នុងករណីខ្លឹមសារផ្ទុយគ្នា គោលការណ៍ និងវិធាននេះ ត្រូវយកជាគោល ក្នុងការអនុវត្តចំពោះបុគ្គលិក អ្នកបោះដុំ អ្នកលក់រាយ និងអ្នកទិញឆ្នោតសន្លឹក “ផ្ការំដួល”។",
            },
            part2: {
              title: "ផ្នែកទី ២ ៖ និយមន័យ",
              option1: "១. ​“ឆ្នោតសន្លឹកផ្សងសំណាង” សំដៅ​លើ​ឆ្នោត​សន្លឹក​​ ​“ផ្ការំដួល” ប្រភេទសន្លឹកដែលមានលេខផ្សងសំណាង0៦ខ្ទង់ស្រាប់ អ្នកទិញគ្រាន់តែជ្រើសរើស ។",
              option2: "២. “ដុំឆ្នោតសន្លឹក”​​​ សំដៅ​លើ​ដុំ​សន្លឹ​ក​ឆ្នោតដែលខ្ចប់ជាដុំ ដែលក្នុង​មួ​យដុំមាន​សន្លឹក​ឆ្នោត១០០សន្លឹក។",
              option3: "៣. “​​​សញ្ញាពិសេស​​” នៅលើឆ្នោតសន្លឹកគឺ​ជាផ្នែកសំខាន់ដើម្បីការពារការក្លែងបន្លំរបស់ជនខិលខូច ។",
              option4: "៤. ​​“ឆ្នោតសន្លឹកដែលឈ្នះ​ប្រាក់​រង្វាន់​” គឺ​​ត្រូវបង្ហាញថា​មានលេខដូចគ្នាទៅនឹងលទ្ធផលឆ្នោតសន្លឹកដែលធ្វើការប្រកាសតាមរយៈ គេហទំព័រ wingolotto.com, Telegram Chanel Facebook Page និង You Tube ផ្លូវការរបស់ក្រុមហ៊ុន ​​​។",
              option5: "៥. “​អ្នក​លក់​រាយ” គឺ​ជា​បុគ្គល ឬ​នីតិ​បុគ្គល ដែលត្រូវបាន​អនុញ្ញាត​​ឲ្យ​លក់​ឆ្នោតសន្លឹក​​ “ផ្ការំដួល”។",
              option6: "៦. “​លេខ​កូដ​​ផ្ទៀង​ផ្ទាត់សំរាប់​អ្នក​លក់​រាយ​”គឺជា​លេខ​កូដ​ទំហំល្មម​ចំនួនមួយនៅផ្នែកខាង​​ក្រោមនៃបង្កាន់ដៃឆ្នោត ដែល​អ្នក​លក់​រាយ​​ពេញ​សិទិ្ធអាច​ប្រើដើម្បី​ផ្ទៀង​ផ្ទាត់ និង​បញ្ជាក់​ពីកម្រិតរង្វាន់បង្កាន់ដៃឆ្នោតឈ្នះ​ប្រាក់។ ​​​​លេខ​កូដ​​ផ្ទៀង​ផ្ទាត់សំរាប់​អ្នក​លក់​រាយនឹង​​ត្រូវ​​កំណត់នៅ​​​ក្នុង​ឯកសារ​​​​​​​​​​ការ​ងារ​​ឆ្នោត​សន្លឹក​ ​​និង​​គោល​ការណ៍​ណែ​នាំ និង​វិធាន​គ្រប់​គ្រង​ឆ្នោត​​។​",
              option7: "៧.  “លេខផ្សងសំណាង​​” សម្រាប់​ “ឆ្នោតសន្លឹកផ្សងសំណាង”​​​ មាន​ដូច​ខាងក្រោម​៖",
              exoption7: "ឧទាហរណ៍៖ 123456 , 888888 , 000999, 555555 , 000229 ។",
              option8: "៨.  ​“ឆ្នោតសន្លឹកផ្សងសំណាង” មានទំហំ W125mm x H58 mm ជាប្រភេទក្រដាស់ទន់រលោង ។",
            },
            part3: {
              title: "ផ្នែកទី ៣ ៖ គំរូសន្លឹកឆ្នោត",
              front: "ខាងមុខ",
              back: "ខាងក្រោយ",
            },
            part4: {
              title: "ផ្នែកទី ៤ ៖ ការកំណត់ពីអ្នកឈ្នះប្រាក់រង្វាន់ឆ្នោតសន្លឹក",
              content: "លេខដែលចាត់ថាជាលេខដែលឈ្នះរង្វាន់ ចាំបាច់ត្រូវតែមានលេខដូចគ្នា មានលំដាប់លេខដូចគ្នា ដោយរាប់ពីស្តាំ មកឆ្វេង ។ អ្នកកាន់ឆ្នោតសន្លឹក អាចមានឱកាសផ្ទៀងផ្ទាត់រង្វាន់ច្រើនដងនៅលើសន្លឹកឆ្នោតតែមួយ។ ប្រាក់រង្វាន់មានដូចខាងក្រោម៖",
              option1: `1.	រង្វាន់តូចទី៨ (2D)៖ លេខសំណាង២ខ្ទង់ចុងក្រោយនៃសន្លឹកឆ្នោតផ្សងសំណាង ត្រូវតាមលំដាប់លេខរៀងដូចគ្នាទៅនឹងលទ្ធផលឆ្នោតសន្លឹក២ខ្ទង់នៅជួរកំរិតរង្វាន់ទី៨ ហើយអាចផ្ទៀតផ្ទាត់បានចំនួន៣ដង។`,
              exoption1: `ឧទាហរណ៍៖ លទ្ធផលឆ្នោត 64 – 98 - 78 ហើយលេខសំណាងរបស់អ្នកគឺ 64 - 98 - 78 ដូចគ្នា នោះអ្នកនឹងឈ្នះរង្វាន់ទី៨ មានទឹកប្រាក់ចំនួន 10,000 រៀល។`,
              option2: `2.	រង្វាន់តូចទី៧ (3D)៖ លេខសំណាង៣ខ្ទង់ចុងក្រោយនៃសន្លឹកឆ្នោតផ្សងសំណាង ត្រូវតាមលំដាប់លេខរៀងដូចគ្នាទៅនឹងលទ្ធផលឆ្នោតសន្លឹក៣ខ្ទង់នៅជួរកំរិតរង្វាន់ទី៧ ហើយអាចផ្ទៀតផ្ទាត់បានចំនួន២ដង។`,
              exoption2: `ឧទាហរណ៍៖ លទ្ធផលឆ្នោត 321 - 654 ហើយលេខសំណាងរបស់អ្នកគឺ 321 - 654 ដូចគ្នា នោះអ្នកនឹងឈ្នះរង្វាន់ទី៧ មានទឹកប្រាក់ចំនួន 50,000 រៀល។`,
              option3: `3.	រង្វាន់មធ្យមទី៦ (3D)៖ លេខសំណាង៣ខ្ទង់ចុងក្រោយនៃសន្លឹកឆ្នោតផ្សងសំណាង ត្រូវតាមលំដាប់លេខរៀងដូចគ្នាទៅនឹងលទ្ធផលឆ្នោតសន្លឹក៣ខ្ទង់នៅជួរកំរិតរង្វាន់ទី៦ ហើយអាចផ្ទៀតផ្ទាត់បានចំនួន២ដង។`,
              exoption3: `ឧទាហរណ៍៖ លទ្ធផលឆ្នោត 951 - 753 ហើយលេខសំណាងរបស់អ្នកគឺ 951 - 753 ដូចគ្នា នោះអ្នកនឹងឈ្នះរង្វាន់ទី៦ មានទឹកប្រាក់ចំនួន 100,000 រៀល។`,
              option4: `4.	រង្វាន់មធ្យមទី៥ (4D)៖ លេខសំណាង៤ខ្ទង់ចុងក្រោយនៃសន្លឹកឆ្នោតផ្សងសំណាង ត្រូវតាមលំដាប់លេខរៀងដូចគ្នាទៅនឹងលទ្ធផលឆ្នោតសន្លឹក៤ខ្ទង់នៅជួរកំរិតរង្វាន់ទី៥ ហើយអាចផ្ទៀតផ្ទាត់បានចំនួន២ដង។`,
              exoption4: `ឧទាហរណ៍៖ លទ្ធផលឆ្នោត 7410 - 8520 ហើយលេខសំណាងរបស់អ្នកគឺ 7410 - 8520 ដូចគ្នា នោះអ្នកនឹងឈ្នះរង្វាន់ទី៥ មានទឹកប្រាក់ចំនួន 200,000 រៀល។`,
              option5: `5.	រង្វាន់មធ្យមទី៤ (4D)៖ លេខសំណាង៤ខ្ទង់ចុងក្រោយនៃសន្លឹកឆ្នោតផ្សងសំណាង ត្រូវតាមលំដាប់លេខរៀងដូចគ្នាទៅនឹងលទ្ធផលឆ្នោតសន្លឹក៤ខ្ទង់នៅជួរកំរិតរង្វាន់ទី៤ ហើយអាចផ្ទៀតផ្ទាត់បានចំនួន២ដង។`,
              exoption5: `ឧទាហរណ៍៖ លទ្ធផលឆ្នោត 3210 - 4567 ហើយលេខសំណាងរបស់អ្នកគឺ 3210 - 4567 ដូចគ្នា នោះអ្នកនឹងឈ្នះរង្វាន់ទី៤ មានទឹកប្រាក់ចំនួន 1,000,000 រៀល។`,
              option6: `6.	រង្វាន់ធំទី៣ (5D)៖ លេខសំណាង៥ខ្ទង់ចុងក្រោយនៃសន្លឹកឆ្នោតផ្សងសំណាង ត្រូវតាមលំដាប់លេខរៀងដូចគ្នាទៅនឹងលទ្ធផលឆ្នោតសន្លឹក៥ខ្ទង់នៅជួរកំរិតរង្វាន់ទី៣ ហើយអាចផ្ទៀតផ្ទាត់បានចំនួន១ដង។`,
              exoption6: `ឧទាហរណ៍៖ លទ្ធផលឆ្នោត 54321 ហើយលេខសំណាងរបស់អ្នកគឺ 54321 ដូចគ្នា នោះអ្នកនឹងឈ្នះរង្វាន់ទី៣ មានទឹកប្រាក់ចំនួន 5,000,000 រៀល។`,
              option7: `7.	រង្វាន់ធំទី២ (5D)៖ លេខសំណាង៥ខ្ទង់ចុងក្រោយនៃសន្លឹកឆ្នោតផ្សងសំណាង ត្រូវតាមលំដាប់លេខរៀងដូចគ្នាទៅនឹងលទ្ធផលឆ្នោតសន្លឹក៥ខ្ទង់នៅជួរកំរិតរង្វាន់ទី២ ហើយអាចផ្ទៀតផ្ទាត់បានចំនួន១ដង។`,
              exoption7: `ឧទាហរណ៍៖ លទ្ធផលឆ្នោត 98756 ហើយលេខសំណាងរបស់អ្នកគឺ 98756 ដូចគ្នា នោះអ្នកនឹងឈ្នះរង្វាន់ទី២ មានទឹកប្រាក់ចំនួន 10,000,000 រៀល។`,
              option8: `8.	រង្វាន់ធំទី១ (6D)៖ លេខសំណាង៦ខ្ទង់ចុងក្រោយនៃសន្លឹកឆ្នោតផ្សងសំណាង ត្រូវតាមលំដាប់លេខរៀងដូចគ្នាទៅនឹងលទ្ធផលឆ្នោតសន្លឹក៦ខ្ទង់នៅជួរកំរិតរង្វាន់ទី១ ហើយអាចផ្ទៀតផ្ទាត់បានចំនួន១ដង។`,
              exoption8: `ឧទាហរណ៍៖ លទ្ធផលឆ្នោត 123456 ហើយលេខសំណាងរបស់អ្នកគឺ 123456 ដូចគ្នា នោះអ្នកនឹងឈ្នះរង្វាន់ធំទី១ មានទឹកប្រាក់ចំនួន 100,000,000 រៀល។`,
            },
            part5: {
              title: "ផ្នែកទី ៥ ៖ តម្លៃនិងកំរិតប្រាក់រង្វាន់សងត្រឡប់",
              option1: "តម្លៃ “ឆ្នោតសន្លឹកផ្សងសំណាង” ១សន្លឹកមានតម្លៃ = ២,០០០រៀល",
              option2: "មួយស៊េរីសន្លឹកឆ្នោតមានចំនួន១,០០០,០០០សន្លឹក និងមានតម្លៃសរុបចំនួន ២,០០០,០០០,០០០រៀល។",
              option3: "ប្រាក់រង្វាន់សងត្រឡប់មានចំនួនសរុប ១,០៩០,០០០,០០០រៀល។",
              option4: "ចំនួនសន្លឹកឆ្នោតត្រូវរង្វាន់មានចំនួនសរុប ៣៤,៤២១សន្លឹក។",
              option5: "ទំហំប្រាក់រង្វាន់ធំ ១០០,០០០,០០០រៀល ត្រូវមកក្រុមហ៊ុនដើម្បីបើកប្រាក់រង្វាន់ដោយភ្ជាប់មកនូវអត្តសញ្ញាណបណ្ណដើមមកជាមួយ។",
              option6: "កម្រិតរង្វាន់សងត្រឡប់សម្រាប់ឆ្នោត “ឆ្នោតសន្លឹកផ្សងសំណាង”  គឺពឹងលើសំណាង ដោយមិនមានការរៀបចំលទ្ធផលទុកមុនឡើយ ។",
              option7: "សន្លឹកឆ្នោតដែលត្រូវរង្វាន់មានសុពលភាព ៣០ថ្ងៃ ចាប់ពីថ្ងៃចេញលទ្ធផល។",
              option8: "លក្ខខណ្ឌនៃការឈ្នះ៖ សន្លឹកឆ្នោតត្រូវតែនៅដដែល ទំហំពេញ មិនរហែក មិនបិត មិនលុប មិនកាត់ត។",
              option9: "អតិថិជនត្រូវរក្សាសន្លឹកឆ្នោតអោយបានល្អ ដើម្បីផ្ទៀងផ្ទាត់លទ្ធផលឆ្នោតផ្សងសំណាង។  អតិថិជនមិនអាចទាម ទារបើករង្វាន់ឆ្នោតសន្លឹកផ្សងសំណាងបានឡើយក្នុងករណីបានធ្វើអោយជ្រុះបាត់សន្លឹកឆ្នោត។",
              option10: "ចេញលទ្ធផល រៀលរាល់ថ្ងៃ នៅវេលាម៉ោង ៦:៣០ នាទីល្ងាច នៅទីស្នាក់ការកណ្តាលរបស់ក្រុមហ៊ុន។",
              option11: `រង្វាន់ដែលត្រូវ គឺផ្តល់ជូនតែមួយដងគត់ ការផ្តល់ជាប្រាក់សុទ្ធ ឬវេរតាមរយៈធនាគារ ។`,
            },
            part6: {
              title: "ផ្នែកទី ៦ ៖ របៀបលេង",
              option1: "លោកអ្នកគ្រាន់តែជ្រើសរើសយកសន្លឹកឆ្នោតដែលមានលេខសំណាងស្រាប់ចំនួន ៦ខ្ទង់ ក្នុងចំណោមលេខទាំង 1,000,000លេខ ចាប់ពី 000000 ដល់ 999999 រួចបញ្ជាទិញពីតំណាងចែកចាយ។ ឧទាហរណ៍៖ 123456 , 139572 , 456789 បន្ទាប់មកត្រូវរក្សាទុកសន្លឹកឆ្នោតដែលមានលេខសំណាង ដើម្បីផ្ទៀងផ្ទាត់នៅពេលប្រកាសលទ្ធផលឆ្នោត និងទទួលរង្វាន់។",
              option2: "លទ្ធផលឆ្នោតសន្លឹក នឹងចេញលទ្ធផលដោយការក្រឡុកចាប់លេខតាមរយៈប្រព័ន្ធអេឡិចត្រូនិច និងត្រូវផ្សាយបន្តផ្ទាល់លើគេហទំព័ររបស់ក្រុមហ៊ុនឆ្នោតវីនហ្គោ។ ដោយបង្វិលចាប់រង្វាន់ចំនួន ១៤ដងក្នុង ១ស៊េរីនៃសន្លឹកឆ្នោត។",
              option3: "ឆ្នោត១សន្លឹកអាចមានឱកាសផ្សងសំណាងចំនួន១៤ដង ហើយក៏មានឱកាសផ្សងសំណាងឈ្នះលើសពីមួយដង ទៅតាមកំរិតរង្វាន់ដែលបានកំណត់របស់ក្រុមហ៊ុន។",
              option4: "បញ្ជាក់៖ ",
              option5: "លេខសំណាងទាំងអស់ដែលត្រូវរង្វាន់ និងលទ្ធផលឆ្នោតសន្លឹក ចាំបាច់ត្រូវតាមលំដាប់លេខរៀង។",
            },
            part7: {
              title: "ផ្នែក​ទី ៧ ៖ ​​​​​​លក្ខ​ខណ្ឌតម្រូវ​​នៃការ​​ផ្ទៀង​ផ្ទាត់​​សន្លឹក​ឆ្នោត",
              option1: "១. ​​លេខសំណាង​​នីមួយៗត្រូវ​​តែ​មាន​ទ្រង់ទ្រាយ​ទាំង​មូល​ និង​អាច​អាន​បាន​ពេញ​លេញ​។",
              option2: "២. សន្លឹកឆ្នោតត្រូវតែនៅដដែល ទំហំពេញ មិនរហែក មិនបិត មិនលុប មិនកាត់ត ឬ​កែ​ប្រែ​​​​តាម​របៀប​ណា​ឡើយ។",
              option3: "៣. ឆ្នោតសន្លឹកទាំង​មូល ឬ​ផ្នែក​ណា​មួយ ​មិន​ត្រូវ​​ក្លែង​ក្លាយ។ អ្នកក្លែងបន្លំសន្លឹកឆ្នោតឈ្នះរង្វាន់ត្រូវប្រឈមចំពោះ មុខច្បាប់ ឬធ្វើកិច្ចសន្យាណាមួយជាមួយក្រុមហ៊ុន ។",
              option4: "៤. ​ឆ្នោតសន្លឹក​ត្រូវ​តែ​ចេញ​ដោយ​​​​​​ក្រុម​ហ៊ុន​ឆ្នោត​ តាម​របៀប​ដែល​ត្រូវ​បាន​អនុញ្ញាត។",
              option5: "៥. ​ឆ្នោតសន្លឹក​​​មិន​មែនជា​​​​​​​​​ឆ្នោត​​ដែលគេ​លួចឬ ​​​​មិន​ត្រូវ​ក្នុង​បញ្ជីឆ្នោត​ដែល​លុប​ចោល ការហួសសុពលភាពប្ដូររង្វាន់នៅក្រុម​ហ៊ុន​ឆ្នោត​ឡើយ។",
              option6: "៦. លេខ​ផ្ទៀង​ផ្ទាត់ឆ្នោតសន្លឹក​នៃ​ឆ្នោត​ដែល​ឈ្នះ​ប្រាក់​រង្វាន់ ​ត្រូវ​តែ​មាន​នៅ​ក្នុង​បញ្ជី​​​​​​​លេខ​​ផ្ទៀង​ផ្ទាត់​​ របស់​ក្រុម​ហ៊ុន​​ឆ្នោតហើយ​ឆ្នោតសន្លឹក​ដែល​មាន​លេខ​ផ្ទៀង​ផ្ទាត់​នោះមិន​បាន​បើក​ប្រាក់​ពី​មុន​មក​ឡើយ។",
              option7: "៧. ឆ្នោតសន្លឹកទាំង​មូល ឬ​មួយ​ផ្នែក​​ មិនត្រូវ​នៅ​​ទទេ​ ចុះ​បញ្ជី​ខុស​ មាន​វិការៈ ឬ​ព្រីន ឬ​ផលិត​​ដោយ​​មាន​កំហុស​ឡើយ។",
              option8: "៨. ​​​​​​​លេខ​​​សំណាងនីមួយៗលើបង្កាន់ដៃឆ្នោត​ ត្រូវ​តែព្រឺន​​​​ដោយ​ប្រើ​ពុម្ភ​អក្សរ​​​ ដែល​ប្រើ​សម្រាប់​សញ្ញា​ និង​​ត្រូវ​តែ​​ស៊ី​សង្វាក់​​នឹង​​​រូប​រ​ចនា​​ក្នុង​​​​​ឯកសារ​នៅ​ក្រុម​ហ៊ុន​ឆ្នោត​។ ចំណង​ជើង​​​នីមួយៗត្រូវ​តែ​ស៊ី​សង្វាក់​នឹង​​​រូប​រចនា​​ក្នុង​ឯកសារ​នៅ​ក្រុម​ហ៊ុន​ឆ្នោត​។",
              option9: "៩. ឆ្នោតសន្លឹក​ត្រូវ​តែ​​​ឆ្លង​ផុត​តេស្ដផ្ទៀង​ផ្ទាត់បន្ថែម​ទាំង​អស់ ដែល​ចែង​ក្នុង​វិធានគ្រប់​គ្រង​​ឆ្នោតវីនហ្គោ​ និងតេស្ដ​ផ្ទៀង​ផ្ទាត់​ជាការ​សម្ងាត់​របស់​ក្រុមហ៊ុន​ឆ្នោត។",
            },
          },
          // history
          history: {
            header: "លទ្ធផលឆ្នោតសន្លឹកផ្សងសំណាងផ្ការំដួលថ្ងៃទី{{day}} ខែ{{month}} ឆ្នាំ{{year}} ម៉ោង {{hour}}:{{min}}{{period}}",
            header1: "លទ្ធផលឆ្នោតសន្លឹកផ្សងសំណាងផ្ការំដួល",
            header2: "ថ្ងៃទី{{day}} ខែ{{month}} ឆ្នាំ{{year}} ម៉ោង {{hour}}:{{min}}{{period}}",
          },
          // timecount
          timecount: {
            showtitle: "លទ្ធផលឆ្នោតសន្លឹក ផ្ការំដួល នាថ្ងៃបន្ទាប់",
            showdate: "នឹងចេញនៅថ្ងៃទី {{day}} ខែ {{month}} ឆ្នាំ {{year}}",
            showtime: "វេលាម៉ោង {{hour}}:{{minute}} {{time}}",
            title: "នៅសល់ពេល",
            time: {day: "ថ្ងៃ", hour: "ម៉ោង", minute: "នាទី", second: "វិនាទី"},
          },
          livehistory: {
            header: "លទ្ធផលឆ្នោតសន្លឹក ផ្ការំដួល បានចេញ",
            header1: "ថ្ងៃទី {{day}} ខែ {{month}} ឆ្នាំ {{year}} ម៉ោង {{hour}}:{{minute}} {{time}}",
          },
        },
      },
      cn: {
        translation: {
          language: "中国人",
          menubar: {
            homepage: "主页",
            history: "历史结果",
            guidance: "指导",
            legality: "合法性",
            about: "关于",
            corporate: "公司",
          },
          // root
          root: {
            notfake: "真正的奖励，确切而且令人信服。",
            rankreward: "排名奖励",
            rewardprice: "奖励价格",
            result: "抽奖结果",
            reward: "二等奖 ",
          },
          // table
          table: {
            reward2D: "2D 幸运抽奖",
            reward3D: "3D 幸运抽奖",
            reward4D: "4D 幸运抽奖",
            reward5D: "5D 幸运抽奖",
            rewardBronze: "大奖杯 '功德' 6D",
            rewardSilver: "大奖杯 '金钱' 6D",
            rewardGold: "大奖杯 '黄金' 6D",
          },
          // about company
          aboutcompany: {
            about: {
              header: "*** 关于公司 ***",
              title: "柬埔寨有着多样化的经济增长，并且拥有多种类型的国家利益，如销售农产品、食品和饮料、提供旅游服务、国际物流服务以及各种类型的润滑油。这些都表明，柬埔寨的商业竞争激烈且提供了多种多样的服务给客户。在这种情况下，柬埔寨的企业竞争激烈且提供了多种多样的服务给客户。由于润滑油的重要性，维克诺·马格纳对于润滑油的参与非常积极，他强调了以下关键要素：可靠性、品质、可持续性。随着时间的推移，公司润滑油的质量在柬埔寨得到了认可，因为人们认识到：",
              option1: "1. 在市场中的润滑油被提前评估，这对于客户来说并不容易",
              option2: "2. 市场中的润滑油需要进行改进，以便更容易获得小奖项，而不是大奖项",
              option3: "3. 市场中的润滑油具有在购买、销售和支付奖金时的方便性。",
              conclusion: "根据以上所述，使客户始终获得正确的润滑油编号，并且从不遇到违反法律的行为是至关重要的。为了解决上述问题，维克诺·马格纳积极参与并执行了100％的润滑油分析报告和管理概述。",
              finally: "维克诺·马格纳公司是由柬埔寨技术专家成立的，旨在为柬埔寨人民提供真正的优质润滑油，以及提供多样化的奖励，并始终在手中保持干净的环境。维克诺·马格纳公司的润滑油的成功也反映在柬埔寨的科技文献中，其中包括柬埔寨国家语言的命名，文字和清晰的传递从润滑油改进到润滑油加工的信息。",
            },
            vision: {
              header: "*** 公司愿景 ***",
              content: "成为柬埔寨润滑油市场的领导者，依靠可靠性，可持续性和专业知识。",
            },
            mission: {
              header: "*** 使命 ***",
              content: "通过国际市场分配中的重要市场地位，加入柬埔寨经济社会发展2025。",
            },
            advantage: {
              header: "*** 优势 ***",
              option1: "1. 法律遵从：注重为各种客户提供法律遵从的政策建议。",
              option2: "2. 社会责任：公司利润的一部分将用于参与社会公益活动。",
              option3: "3. 治理：公司拥有远见、价值观、文化和传统，并有一支有效的管理团队和专业员工。",
              option4: "4. 产品：优质的产品质量，多样的产品奖励，真实的奖励和高质量的服务。",
              option5: "5. 系统：办公地点设施齐全，提供高效的工作环境，帮助降低成本并增加收入。",
              option6: "6. 网络：广泛的市场网络，可以有效地推广和推动业务发展。",
            },
            culture: {
              header: "*** 文化 ***",
              integrity: {
                title: "诚信: 我们通过以下方式诚信经营业务:",
                option1: "❶ 诚实守信",
                option2: "❷ 重视道德原则",
                option3: "❸ 优先考虑道德和人文价值",
              },
              accountability: "高度问责制: 我们通过及时承担责任、及时回应问题、增强团队合作、避免推卸责任、不制造借口以规避责任等方式来自我问责。",
              service: "良好的客户服务: 提供满意度、谦虚、避免傲慢、及时提供信息、花时间解释公司的服务、及时回应需求、理解客户情况、帮助找到解决方案、表现出同情心，并倾听客户反馈。",
              professional: "专业化: 保持专业操守、守时、专注于工作、使用适当的语言、守时、考虑行动的影响、承担责任、交付工作结果，专业精神是我们工作的准则。",
              right_thing: "第一时间做对事情: 我们认识到，如果一开始就不能做好事情，我们将浪费宝贵的时间、声誉、资源、核心价值和客户信任。我们承诺不妥协，并将努力在一开始就做到卓越。如果失败，我们会从错误中吸取教训并再次尝试。",
              focus: "专注于一件事情: 我们花更多时间专注于一项任务，而不是接受多个任务。我们相信，将注意力集中在一件事情上将使我们能够做好并高效地完成该任务。",
              learning: "持续学习: 公司鼓励每个人继续学习，因为如果我们想要更多，我们就会得到更多。我们强烈鼓励: ❶ 学习新事物、 ❷ 向他人学习、 ❸ 从过去的经验中学习、 ❹ 多读书。",
              believe: {
                title: "相信 - 一切皆有可能:",
                option1: "❶ 决心: 决心是成功的关键，因为如果我们不决心专注于它，我们永远不会实现任何目标。我们决心专注于公司的目标、公司的产品、公司的工作系统、团队合作、自我专注，并相信“一切皆有可能”和“我们能做到”。",
                option2: "❷ 乐观: 许多人因为过去的成就而提前放弃了他们的目标或梦想。我们相信“不要放弃”和“我们能做到”是成功的重要信念。我们相信“失败不是浪费”和“我们会再试一次”。",
              },
              teamwork: "团队合作: ❶ 设定明确的团队目标 ❷ 均衡分配工作 ❸ 相互信任 ❹ 建立良好的关系 ❺ 倾听彼此 ❻ 相互支持 ❼ 和平解决冲突 ❽ 帮助彼此成长 ❾ 共同庆祝成功。",
              gratitude: {
                title: "感恩: 感恩是成功的关键之一。如果我们在拥有时对拥有的东西表示感恩，我们将得到许多祝福。因此，我们感激:",
                option1: "• 感谢父母从一开始就给予了我们的生命和抚养。",
                option2: "• 感谢这个国家提供了可以让我们成功的机会。",
                option3: "• 感谢公司为我们提供工作、收入、额外培训机会，使我们的生活繁荣，我们的人民幸福。",
              },
            },
          },
          // guidance
          guidance: {
            title: {
              header: "报告和管理总结",
              title: "润滑油分析报告",
              brand: "“保养工厂”",
            },
            part1: {
              title: "第一部分：报告概述",
              content: "本润滑油分析报告和管理概述专为“保养工厂”润滑油制定，同时也为政府部门提供了额外的管理指导。在这种情况下，政府管理也必须适用于“保养工厂”的润滑油。这份报告和管理对于员工、供应商、销售代表和购买“保养工厂”润滑油的人士都是至关重要的。",
            },
            part2: {
              title: "第二部分：细节",
              option1: "1. “标准润滑油”针对只选择具有6个标准编号的润滑油的购买者。",
              option2: "2. “批量润滑油”针对批量购买润滑油并将其混合在一起的情况。对于“保养工厂”的润滑油，每个批次有1000个油品，并且有从000000到999999的编号，总共有1,000,000（一百万）个润滑油在一个桶里。",
              option3: "3. “特殊条件”在润滑油上是一个重要部分，旨在保护受影响群体免受可能的危害。",
              option4: "4. “润滑油彩票”必须显示相同的数字，以符合通过网站wingolotto.com、电报频道和公司的Facebook页面发布的润滑油彩票的结果。",
              option5: "5. “销售代表”是允许出售“保养工厂”润滑油的公司或个人。",
              option6: "6. “验证代码”是一个在润滑油容器底部的小型代码，售后服务人员可以用来验证和说明彩票润滑油的奖金水平。验证代码必须在润滑油分析报告和管理概述中确定。",
              option7: "7. “特殊编号”用于“标准润滑油”，如下所示：例如：123456，888888，000999，555555，000229。",
              option8: "8. “标准润滑油”的尺寸为W125mm x H58 mm，是一种矩形容器。",
            },
            part3: {
              title: "第三部分：润滑油案例",
            },
            part4: {
              title: "第四部分：指南彩票押注规则",
              content: "在选择彩票号码时，应该注意选择与选定彩票相同数量的号码，并且这些号码必须按照相同的顺序。 押注者可以在一张彩票上选择多个奖项。",
              option1: "1. 第12名奖项（2D）：选定的彩票号码的最后两位数字必须与第12名奖项的结果的最后两位数字相同。 示例：如果抽奖结果是01、12，而您的选定号码是12，则您将获得第12名奖项，奖金为10,000卢布。",
              option2: "2. 第11名奖项（2D）：选定的彩票号码的最后两位数字必须与第11名奖项的结果的最后两位数字相同。 示例：如果抽奖结果是22，而您的选定号码是22，则您将获得第11名奖项，奖金为20,000卢布。",
              option3: "3. 第10名奖项（3D）：选定的彩票号码的最后三位数字必须与第10名奖项的结果的最后三位数字相同。 示例：如果抽奖结果是331、332，而您的选定号码是332，则您将获得第10名奖项，奖金为50,000卢布。",
              option4: "4. 第9名奖项（4D）：选定的彩票号码的最后四位数字必须与第9名奖项的结果的最后四位数字相同。 示例：如果抽奖结果是4441、4442、4443、4444、4445、4446、4447、7778、4449、4450，而您的选定号码是4442，则您将获得第9名奖项，奖金为100,000卢布。",
              option5: "5. 第8名奖项（4D）：选定的彩票号码的最后四位数字必须与第8名奖项的结果的最后四位数字相同。 示例：如果抽奖结果是5551、5552，而您的选定号码是5552，则您将获得第8名奖项，奖金为200,000卢布。",
              option6: "6. 第7名奖项（4D）：选定的彩票号码的最后四位数字必须与第7名奖项的结果的最后四位数字相同。 示例：如果抽奖结果是5555，而您的选定号码是5555，则您将获得第7名奖项，奖金为500,000卢布。",
              option7: "7. 第6名奖项（5D）：选定的彩票号码的最后五位数字必须与第6名奖项的结果的最后五位数字相同。 示例：如果抽奖结果是66641、66642、66643、66644、66645、66646，而您的选定号码是66645，则您将获得第6名奖项，奖金为1,000,000卢布。",
              option8: "8. 第5名奖项（5D）：选定的彩票号码的最后五位数字必须与第5名奖项的结果的最后五位数字相同。 示例：如果抽奖结果是66651、66652、66653，而您的选定号码是66651，则您将获得第5名奖项，奖金为2,000,000卢布。",
              option9: "9. 第4名奖项（5D）：选定的彩票号码的最后五位数字必须与第4名奖项的结果的最后五位数字相同。 示例：如果抽奖结果是66666，而您的选定号码是66666，则您将获得第4名奖项，奖金为5,000,000卢布。",
              option10: "10. 大奖“特别”（6D）：选定的彩票号码的最后六位数字必须与大奖“特别”奖项的结果的最后六位数字相同。 示例：如果抽奖结果是777771、777772、777773、777774，而您的选定号码是777773，则您将获得大奖“特别”奖项，奖金为10,000,000卢布。",
              option11: "11. 大奖“金钱”（6D）：选定的彩票号码的最后六位数字必须与大奖“金钱”奖项的结果的最后六位数字相同。 示例：如果抽奖结果是888881、888882、888883，而您的选定号码是888881，则您将获得大奖“金钱”奖项，奖金为20,000,000卢布。",
              option12: "12. 大奖“丰富”（6D）：选定的彩票号码的最后六位数字必须与大奖“丰富”奖项的结果的最后六位数字相同。 示例：如果抽奖结果是999999，而您的选定号码是999999，则您将获得大奖“丰富”奖项，奖金为100,000,000卢布。",
            },
            part5: {
              title: "第5部分：彩票奖金和奖池水平",
              option1: "奖金'封闭号码'：每个封闭号码的价值为2000瑞尔。",
              option2: "每份股票有1000万份，总价值为20亿瑞尔。",
              option3: "奖池总金额为10亿6000万瑞尔。",
              option4: "要发行的彩票总数为33,408张。",
              option5: "头奖金额为1亿瑞尔，必须由原始团队通过连接初始身份证来领取。",
              option6: "“封闭号码”彩票的奖金水平较高，因为没有事先安排结果。",
              option7: "有资格获奖的彩票在结果公布之日起有效期为30天。",
              option8: "投注规则：彩票必须保持完整、完整、未受损、未污染和未改变。",
              option9: "客户必须仔细核对他们的彩票以确认中奖号码。如果封闭号码的安排已经受损，客户不能要求打开中奖彩票。",
              option10: "彩票奖金结果每周二和周五下午6:30在公司总部公布。",
              option11: "奖品每张有效票只颁发一次，可以是现金或银行转账。",
            },
            part6: {
              title: "第6部分：玩法",
              option1: "- 您只需选择一个包含6个数字的彩票，其中包括从000000到999999的所有数字，并从代理处购买彩票。 例如：654321，888888，000999。 然后，您需要保存包含幸运数字的彩票，以便在公布彩票结果并领取奖金时进行验证。",
              option2: "- 彩票结果将根据由计算机系统生成的数字进行抽奖，并且必须在彩票公司的网站上进行公布。 通过抽取36个球中的一个来确定奖金彩票。",
              option3: "- 每张彩票只能有36个幸运数字，并且还可以有36个备用幸运数字，根据彩票公司的设定水平进行开奖。",
              option4: "- 请注意：所有要抽奖的幸运数字和彩票结果必须按数字顺序排列。",
            },
            part7: {
              title: "第7部分：彩票验证的强制规则",
              option1: "1. 每个中奖号码必须清晰、易读，并完整可读。",
              option2: "2. 彩票必须完整，不能撕裂、损坏、更改或修改。",
              option3: "3. 整张彩票或其任何部分都不能是伪造的。伪造的彩票将使得奖金索赔无效，并可能导致对任何涉及方采取法律行动或处罚。",
              option4: "4. 彩票必须按照规定程序由彩票公司发行。",
              option5: "5. 遗失、被盗或从彩票名单中排除的彩票无法重新发行，任何尝试以此类彩票兑换奖品的行为都是无效的。",
              option6: "6. 彩票上的序列号必须使用适当的字体进行身份验证，并在彩票公司发行的文件中附有标志或徽标。",
              option7: "7. 每个标题必须在彩票公司发行的文件中附有标志。",
              option8: "8. 彩票上的序列号必须使用适当的字体进行身份验证，并在彩票公司发行的文件中附有标志或徽标。",
              option9: "9. 彩票必须保留至彩票管理机构规定的期限结束，以供彩票公司的额外验证，并作为彩票公司的额外安全措施。",
            },
          },
          // history
          history: {
            header: "抽奖结果将于{{year}}年{{month}}月{{day}}日公布",
          },
          // timecount
          timecount: {
            showtime: "开奖结果将于{{year}}年{{month}}月{{day}}日{{hour}}时公布",
            title: "剩余时间",
            time: {day: "天", hour: "小时", minute: "分钟", second: "秒"},
          },
        },
      },
    },
  });
