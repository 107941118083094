import {Image, VStack} from "@chakra-ui/react";
import React from "react";
import {toAbsoluteUrl} from "../../../utils/helper";

export default function LegalDocument() {
  return (
    <VStack spacing={4} my="10px" px={{ base: "10px" }}>
      <Image src={toAbsoluteUrl("/assets/docs/doc1.png")} />
      <Image src={toAbsoluteUrl("/assets/docs/doc2.png")} />
      <Image src={toAbsoluteUrl("/assets/docs/doc3.png")} />
      <Image src={toAbsoluteUrl("/assets/docs/doc4.png")} />
      <Image src={toAbsoluteUrl("/assets/docs/doc5.png")} />
      <Image src={toAbsoluteUrl("/assets/docs/doc6.png")} />
      <Image src={toAbsoluteUrl("/assets/docs/doc7.png")} />
      <Image src={toAbsoluteUrl("/assets/docs/doc8.png")} />
      <Image src={toAbsoluteUrl("/assets/docs/doc9.png")} />
    </VStack>
  );
}
