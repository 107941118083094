import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Login from "./login";
import BaseAdminPage from "../app";

export default function AuthPage() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/admin/promotion/list" component={BaseAdminPage} />
      <Redirect to="/login" />
    </Switch>
  );
}
