import React, {useEffect, useState} from "react";
import {Flex, Image, Spacer, Menu, MenuButton, IconButton, MenuList, MenuItem, Button, Text, Container, Box} from "@chakra-ui/react";
import {Link, useLocation} from "react-router-dom";
import {toAbsoluteUrl} from "../utils/helper";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";

export default function Navbar() {
  const {t, i18n} = useTranslation();
  const [showLogo, setShowLogo] = useState(false);
  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  // define option
  const languages = [
    {code: "en", label: "English"},
    {code: "km", label: "ភាសាខ្មែរ"},
    {code: "cn", label: "中国人"},
  ];
  const currentLanguage = i18n.language;
  const filteredLanguages = languages.filter((lang) => lang.code !== currentLanguage);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const romduolBanner = document.getElementById("romduol-banner");
      if (romduolBanner) {
        const {top} = romduolBanner.getBoundingClientRect();
        setShowLogo(top < 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Flex fontFamily="Baphnom" as="nav" zIndex="2" py={{base: 2, md: 4, lg: 6}} alignItems={"center"} boxShadow="lg" bg="#EDDBCD" position="sticky" top="0px" whiteSpace="nowrap">
      <Container maxW="1440px">
        <Flex>
          <Menu autoSelect={false}>
            <MenuButton as={IconButton} icon={<Image src={toAbsoluteUrl("/assets/HamburgerIcon.svg")} width={{base: "20px", md: "25px", lg: "28px"}} />} variant="none" />
            <MenuList bg="#EDDBCD" color="#DA232B" fontFamily={"Metal Chrieng"} boxShadow="base">
              <Link to="/home">
                <MenuItem bgColor={location.pathname === "/home" ? "white" : ""} py={{base: "5px", md: "10px", lg: "15px"}}>
                  {t("menubar.homepage")}
                </MenuItem>
              </Link>
              <Link to="/previous-result">
                <MenuItem bgColor={location.pathname === "/previous-result" ? "white" : ""} py={{base: "5px", md: "10px", lg: "15px"}}>
                  {t("menubar.history")}
                </MenuItem>
              </Link>
              <Link to="/guidance">
                <MenuItem bgColor={location.pathname === "/guidance" ? "white" : ""} py={{base: "5px", md: "10px", lg: "15px"}}>
                  {t("menubar.guidance")}
                </MenuItem>
              </Link>
              <Link to="/legality">
                <MenuItem bgColor={location.pathname === "/legality" ? "white" : ""} py={{base: "5px", md: "10px", lg: "15px"}}>
                  {t("menubar.legality")}
                </MenuItem>
              </Link>
              <Link to="/about-company">
                <MenuItem bgColor={location.pathname === "/about-company" ? "white" : ""} py={{base: "5px", md: "10px", lg: "15px"}}>
                  {t("menubar.about")}
                </MenuItem>
              </Link>
            </MenuList>
          </Menu>
          <motion.div animate={{opacity: showLogo ? 1 : 0, y: showLogo ? 0 : 65, x: 3, transition: {duration: 0}}} style={{display: showLogo ? "block" : "block"}}>
            <Box onClick={scrollToTop}>
              <Image src={toAbsoluteUrl("/assets/Wingo Logo.png")} alt="Wingo logo" width="45px" ml={2} display={{base: "block", md: "none", lg: "none"}} />
            </Box>
          </motion.div>
          <Box onClick={scrollToTop}>
            <Image src={toAbsoluteUrl("/assets/Wingo Logo.png")} alt="Wingo logo" width="45px" ml={2} display={{base: "none", md: "block", lg: "block"}} />
          </Box>
          <Spacer />
          <Menu>
            <MenuButton as={Button} disabled rightIcon={<Image src={toAbsoluteUrl("/assets/ChevronDownIcon.svg")} width={{base: "10px", md: "15px", lg: "20px"}} />} p="0 25px" leftIcon={<Image src={toAbsoluteUrl("/assets/global.svg")} width={{base: "15px", md: "20px", lg: "25px"}} />} variant="none">
              <Text
                fontWeight="normal"
                fontSize={{base: "15px", md: "20px", lg: "25px"}}
                color="#A9411B"
                transform={{
                  base: "translateY(1px)",
                  md: "translateY(2px)",
                  lg: "translateY(3px)",
                }}
              >
                {t("language")}
              </Text>
            </MenuButton>
            <MenuList bg="#EDDBCD" color="#DA232B" boxShadow="base">
              {filteredLanguages.map((lang) => (
                <MenuItem key={lang.code} onClick={() => handleChangeLanguage(lang.code)}>
                  {lang.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      </Container>
    </Flex>
  );
}
