import { Box, Container, Flex, Image } from "@chakra-ui/react";
import "../App.css";
import InfiniteScrollTableHistory from "../components/Tables/InfiniteScrollTableHistory";
import NavBottom from "../components/navBottom";
import { toAbsoluteUrl } from "../utils/helper";
import { useGetInfiniteHistoryResult } from "../utils/hooks/live-table";

export default function PreviousResultsMobile() {
  const historyResultInfiniteFetch = useGetInfiniteHistoryResult({
    payload: {
      rowsPerPage: 1,
    },
    config: {
      getNextPageParam: (lastPage) =>
        lastPage.pagination && (lastPage.pagination.rowsPerPage * lastPage.pagination.page < lastPage.pagination.total ? lastPage.pagination.page + 1 : false),
      cacheTime: 300000,
    },
  });

  return (
    <>
      <Box bg="#EDDBCD" backgroundImage={toAbsoluteUrl("/assets/bg_patterns.svg")} width="100%" id="no-print">
        <Flex bg="#A9411B" pb={{ base: 1, md: 3, lg: 5 }} mb={{ base: 5, md: 7, lg: 10 }}>
          <Container maxW="1440px" align="center">
            <Image id="romduol-banner" src={toAbsoluteUrl("/assets/romduol-banner.png")} alt="romduol-banner" width="80%" />
          </Container>
        </Flex>
        <Box flex="1" overflow="auto" px={{ base: "8px" }}>
          <InfiniteScrollTableHistory isMobileApp={true} {...historyResultInfiniteFetch} />
        </Box>
        <NavBottom />
      </Box>
    </>
  );
}
