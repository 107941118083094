import { BiBook, BiHome, BiInfoCircle, BsMegaphone, FaTrophy } from "react-icons/all";

export const MenuBar = [
    {
        // name: "",
        item: [
            {
                id: "home",
                name: "ទំព័រដើម",
                path: "/home",
                icon: <BiHome />,
            },
            {
                id: "previous",
                name: "ប្រវត្តិឆ្នោតចេញ",
                path: "/previous-result",
                icon: <BiHome />,
            },
            {
                id: "introduction",
                name: "ការណែនាំ",
                path: "/introduction",
                icon: <BiInfoCircle />,
            },
            {
                id: "legal-document",
                name: "ឯកសារផ្លូវច្បាប់",
                path: "/legal-document",
                icon: <BiBook />,
            },
            {
                id: "about-company",
                name: "ឯកសារផ្លូវច្បាប់",
                path: "/about-company",
                icon: <BiBook />,
            },
        ],
    },
];


export const MenuConstants = [
    {
        item: [
            {
                id: "promotion",
                name: "Promotion Feed",
                path: "/admin/promotion",
                icon: <BsMegaphone />
            },
            {
                id: "monthly-winner",
                name: "Monthly Winner Feed",
                path: "/admin/monthly-winner",
                icon: <FaTrophy />
            },
            // {
            //     id: "change-password",
            //     name: "Change Password",
            //     path: "/admin/change-password",
            //     icon: <HiOutlineRectangleStack />,
            // }
        ],
    },
];

