import { Box } from "@chakra-ui/react";
import InfiniteScrollTableHistory from "../../../components/Tables/InfiniteScrollTableHistory";
import { useGetInfiniteHistoryResult } from "../../../utils/hooks/live-table";

export default function PreviousResults() {
  const historyResultInfiniteFetch = useGetInfiniteHistoryResult({
    payload: {
      rowsPerPage: 1,
    },
    config: {
      getNextPageParam: (lastPage) =>
        lastPage.pagination && (lastPage.pagination.rowsPerPage * lastPage.pagination.page < lastPage.pagination.total ? lastPage.pagination.page + 1 : false),
      cacheTime: 300000,
    },
  });
  return (
    <>
      <Box flex="1" overflow="auto" px={{ base: "8px" }}>
        <InfiniteScrollTableHistory isMobileApp={false} {...historyResultInfiniteFetch} />
      </Box>
    </>
  );
}
