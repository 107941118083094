const PrivacyPolicies = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", margin: "auto", width: "90%", paddingTop: "50px" }}>
            <strong>Privacy Policy for Wingo Lotto Mobile App</strong>
            <h1>
                This Privacy Policy describes how Wingo Lotto Mobile App collects, uses, and protects the information you provide when using the Wingo Lotto Mobile App mobile application.
                Information Collection and Use:
            </h1>
            <ol style={{ listStyleType: "circle", paddingLeft: "50px" }}>
                <li>	<strong>Personal Information:</strong> We may collect personal information such as your name, email address, phone number, and payment details when you register or use our services.</li>

                <li>	<strong>Usage Data:</strong> We may collect information about how you use the app, including your interactions, transactions, preferences, and device information.</li>

                <li>	<strong>Location Data:</strong> With your consent, we may collect and process your location data to provide location-based services and improve user experience.</li>

                <li>	<strong>Information Security:</strong> We prioritize the security of your information and use industry-standard measures to protect against unauthorized access, disclosure, alteration, or destruction. Your data is stored securely and accessed only by authorized personnel for legitimate purposes.</li>

                <li>	<strong>Data Usage and Retention:</strong> We use the information collected to provide and improve our services, personalize user experience, process transactions, and communicate with you to ensure the accuracy. Your data is retained only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.</li>

                <li>	<strong>Third-Party Services:</strong> We may use third-party services, such as analytics providers and payment processors, to enhance app functionality and process transactions securely. These third parties may have their own privacy policies governing data collection, use, and disclosure.</li>

                <li>	<strong>User Rights:</strong> You have the right to access, update, or delete your personal information stored in our app based on the authorised level assigned approval. You may opt out of certain data collection and processing activities, subject to applicable laws and regulations.</li>

                <li>	<strong>Changes to Privacy Policy:</strong> We reserve the right to update or modify this Privacy Policy at any time. Any changes will be notified to you through the app or other communication channels.</li>

                <li>	<strong>Contact Us:</strong>
                    <br />
                    If you have any questions, concerns, or requests regarding this Privacy Policy or your data, please contact us at Wingo Lotto Mobile App. By using the Wingo Lotto Mobile App, you consent to the collection,  use, and processing of your information as described in this Privacy Policy.
                </li>


            </ol>
            <p style={{ width: "100px", margin: "auto" }}>-----End-----</p>
        </div>
    )
}

export default PrivacyPolicies
