import { Box, Button, Center, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Progress, Spacer, Stack, Text, VStack, useDisclosure } from "@chakra-ui/react";
import * as html2canvas from "html2canvas";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useReactToPrint } from "react-to-print";
import { toAbsoluteUrl } from "../../utils/helper";
import { Spinner } from "../Loader";
import TableLastResult from "./TableLastResult";
import TablePrintTemplate from "./TablePrintTemplate";

export default function InfiniteScrollTableHistory({ isMobileApp, data: historyData, isLoading, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage }) {
  const componentRef = useRef(null);
  const [printData, setPrintData] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isImgPrinting, setIsImgPrinting] = useState(false);
  const [dataLoad, setDataLoad] = useState(false);
  const onBeforeGetContentResolve = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMount, setIsMount] = useState(false);

  const reactToPrintContent = useCallback(() => {
    setIsPrinting(true);
    return componentRef.current;
    //eslint-disable-next-line
  }, [componentRef.current]);

  const handleOnBeforeGetContent = () => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setDataLoad(true);
    });
  };
  const handlePrintTable = (time, data) => {
    setPrintData({ time: time, data: data });
    onOpen();
  };
  const handlePrintPDF = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: () => {
      setDataLoad(false);
      setIsPrinting(false);
    },
  });
  const handleSaveImage = useCallback(() => {
    if (componentRef.current === null) return;
    const style = document.createElement("style");
    document.head.appendChild(style);
    style.sheet?.insertRule("body > div:last-child img { display: inline-block !important;}");
    setIsImgPrinting(true);
    const domNode = componentRef.current;
    html2canvas(domNode, {
      scale: 2,
    })
      .then((canvas) => {
        style.remove();
        var a = document.createElement("a");
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = "wingo-result.jpg";
        a.click();
      })
      .then(() => {
        setDataLoad(false);
        setIsImgPrinting(false);
      });
    // scale quality of image
  }, [componentRef]);
  // wait for
  useEffect(() => {
    if (dataLoad) {
      // Resolves the Promise, telling `react-to-print` it is time to gather the content of the page for printing
      onBeforeGetContentResolve.current();
    }
  }, [dataLoad, onBeforeGetContentResolve]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsMount(true);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [isLoading]);

  const flatData = React.useMemo(() => {
    let flatArray = [].concat.apply(
      [],
      historyData?.pages?.map(({ modifiedData }) => modifiedData.map((elem) => elem)),
    );

    return flatArray?.map((e, i) => ({ ...e, n: i + 1 }));
  }, [historyData]);

  const loadMoreButtonRef = React.useRef();

  React.useEffect(() => {
    const handleIntersection = (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    };

    const observer = new IntersectionObserver(handleIntersection);
    const target = loadMoreButtonRef.current;

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [fetchNextPage, isFetchingNextPage, hasNextPage]);

  return (
    <>
      <Modal motionPreset="none" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton position="absolute" right="0.3rem" top="0.3rem" />
          <ModalBody display={"flex"} justifyContent={"center"} gap="2" flexDir={{ md: "row", sx: "column" }}>
            <Button
              width={"45%"}
              bg={"#DA232B"}
              height="50%"
              sx={{ _hover: { bg: "#DA232B" }, _loading: { height: "150px" } }}
              loadingText="Loading"
              color={"white"}
              isLoading={isPrinting}
              onClick={() => handlePrintPDF()}
            >
              <Image src={toAbsoluteUrl("/assets/pdf.svg")} width={"100px"} height="150px" />
              <Box with="100px">PDF</Box>
            </Button>
            <Button
              width={"45%"}
              bg={"#DA232B"}
              height="50%"
              sx={{ _hover: { bg: "#DA232B" }, _loading: { height: "150px" } }}
              loadingText="Loading"
              color={"white"}
              isLoading={isImgPrinting}
              onClick={handleSaveImage}
            >
              <Image src={toAbsoluteUrl("/assets/image.svg")} width={"100px"} height="150px" />
              <Box with="100px">Image</Box>
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box pos="relative" h="full">
        {!isMount ? (
          <Stack h="100vh">
            <Text textAlign="center" fontSize={{ base: "1em", sm: "1.5em", md: "1.8em" }} color="brand.800">
              កំពុងទាញ ...
            </Text>
            <Spacer />
            <Progress size="sm" isIndeterminate />
          </Stack>
        ) : (
          flatData.map((data, idx) => (
            <VStack key={idx}>
              <TableLastResult isMobileApp={isMobileApp} timeLastTable={data.scheduleTime} dataObject={data.dataResponse} handlePrintTable={handlePrintTable} />
            </VStack>
          ))
        )}

        <Box hidden={!isMount} h="min-content">
          {isFetchingNextPage ? (
            <Stack h="100vh">
              <Text textAlign="center" fontSize={{ base: "1em", sm: "1.5em", md: "1.8em" }} color="brand.800">
                កំពុងទាញ ...
              </Text>
              <Spacer />
              {/* <Progress size="xs" colorScheme="blue" isIndeterminate /> */}
              <Progress size="sm" isIndeterminate />
            </Stack>
          ) : hasNextPage ? (
            <Center>
              <Button fontSize={{ base: "1em", sm: "1.5em", md: "1.8em" }} color="brand.800" variant="ghost" onClick={fetchNextPage} disabled={isFetchingNextPage}>
                កំពុងទាញ ...
              </Button>
            </Center>
          ) : (
            !isFetching && (
              <Center color="brand.800">
                <Text fontSize={{ base: "1em", sm: "1.5em", md: "1.8em" }}>{/* មិនមានទិន្នន័យបន្ថែមទេ */}</Text>
              </Center>
            )
          )}
          {isFetching && !isFetchingNextPage && <Spinner />}
        </Box>
        <button ref={loadMoreButtonRef} onClick={fetchNextPage} disabled={!hasNextPage || isFetchingNextPage}></button>
        {createPortal(<TablePrintTemplate ref={componentRef} timeLastTable={printData?.time} data={printData?.data} id="only-print" />, document?.body)}
      </Box>
    </>
  );
}
