import { DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Spacer, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { convertToKhmerMonth } from "../../utils/helper";
import TableHistoryResult from "./TableHistoryResult";

export default function TableLastResult({ isMobileApp, timeLastTable, dataObject, printLoading, handlePrintTable }) {
  const lg = useBreakpointValue({ base: true, lg: false }); // just use for responsive
  const { t } = useTranslation();
  const title = (timeLastTable) => {
    let titleHeader;
    if (timeLastTable !== null && timeLastTable !== undefined) {
      const [datePart, timePart] = timeLastTable.split(" ");
      const [year, month, day] = datePart.split("-");
      const [hour, min] = timePart.split(":");
      titleHeader = (
        <Box key={"title"}>
          {t("history.header", { day: day, month: convertToKhmerMonth(month), year: year, hour: hour > 12 ? hour - 12 : hour, min: min, period: hour > 12 ? "ល្ងាច" : "ព្រឹក" })}
        </Box>
      );
      if (lg) {
        titleHeader = (
          <Box key={"title-lg"}>
            <Box>{t("history.header1")}</Box>
            <Box>
              {t("history.header2", {
                day: day,
                month: convertToKhmerMonth(month),
                year: year,
                hour: hour > 12 ? hour - 12 : hour,
                min: min,
                period: hour > 12 ? "ល្ងាច" : "ព្រឹក",
              })}
            </Box>
          </Box>
        );
      }
    } else titleHeader = `លទ្ធផលឆ្នោត - ថ្ងៃទី- ខែ- ឆ្នាំ-`;
    return [titleHeader];
  };
  const handleClickPrint = () => {
    handlePrintTable(timeLastTable, dataObject);
  };
  if (!timeLastTable || (dataObject === undefined) | null) return null;

  return (
    <Box width={"100%"} mb="20px">
      <Stack my="10px" direction="column">
        <HStack>
          <Box color="#DA232B" fontSize={{ base: "15px", sm: "24px", md: "30px", lg: "36px" }} fontFamily={"Baphnom"}>
            {title(timeLastTable)}
          </Box>
          <Spacer />
          <Box display={isMobileApp ? "none" : "block"}>
            <Button
              backgroundColor="#DA232B"
              borderRadius="0px"
              fontFamily="Baphnom"
              fontWeight="normal"
              color="white"
              fontSize={{ base: "14px", md: "20px", lg: "24px" }}
              p={{ base: "8px 10px", lg: "15px 20px" }}
              leftIcon={<DownloadIcon />}
              onClick={handleClickPrint}
              colorScheme="orange"
            >
              {printLoading ? "..." : "រក្សាទុក"}
            </Button>
          </Box>
        </HStack>

        <Text fontSize={{ base: "12px", md: "16px", lg: "20px" }} fontFamily={"Taprom"} color="#DA232B">
          រង្វាន់មានពិត ឥតក្លែងបន្លំ
        </Text>
      </Stack>
      <TableHistoryResult scheduleTime={timeLastTable} dataResponse={dataObject} />
      {/* <TableLiveResult timeShow={timeLastTable} dataObject={dataObject} staticObject={true} /> */}
    </Box>
  );
}
