import { API_URL } from "../../constants/app";
import { headersJson, headersToken } from "./requestHeaders";

export async function login(payload) {
   return await fetch(`${API_URL}/auth/login`, {
      method: "POST", headers: headersJson(), body: JSON.stringify(payload),
   })
      .then((res) => res.json())
      .then((data) => {
         return data;
      });
}

export async function getUserProfile() {
   return await fetch(`${API_URL}/user/me`, {
      method: "POST", headers: headersToken(),
   })
      .then((response) => response.json())
      .then((data) => {
         if (data.body) {
            let menuPermission = {};
            data.body.moduleTypeList?.forEach(({ name, moduleList }) => {
               let modulePermisson = {};
               moduleList.map(({ type, checked }) => (modulePermisson[type] = checked));
               menuPermission[name] = modulePermisson;
            });
            return {
               ...data.body, photo: data.body.photo, menuPermission: menuPermission,
            };
         }
      });
}

export async function logout() {
   return await fetch(`${API_URL}/auth/logout?deviceId=1`, {
      method: "POST", headers: headersToken(),
   })
      .then((res) => res.json())
      .then((data) => {
         if (data.header.statusCode === 200 && data.header.result) {
            return data;
         }
      });
}
