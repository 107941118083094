import { storage } from "../helper";

export const headers = () => ({
   Accept: "*/*",
});
export const headersJson = () => {
   return {
      Accept: "*/*", "Content-Type": "application/json",
   };
};
export const headersToken = () => {
   const accessToken = storage.getToken();
   return {
      Accept: "*/*", Authorization: `bearer ${accessToken}`,
   };
};
export const headersJsonToken = () => {
   const accessToken = storage.getToken();
   return {
      Accept: "*/*", "Content-Type": "application/json", Authorization: `bearer ${accessToken}`,
   };
};
export const headersFormDataToken = () => {
   const accessToken = storage.getToken();
   return {
      Accept: "*/*", Authorization: `bearer ${accessToken}`,
   };
};
export const headersMultipartFormDataToken = () => {
   const accessToken = storage.getToken();
   return {
      Accept: "*/*", "Content-Type": "multipart/form-data", Authorization: `bearer ${accessToken}`,
   };
};
