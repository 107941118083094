import {useState, useRef} from "react";
import Loader from "react-loader-spinner";
import {HiEyeOff, HiEye, HiUser} from "react-icons/hi";
import {Box, Button, Alert, AlertIcon, InputGroup, InputRightElement, IconButton, chakra, FormControl, FormLabel, Input, Flex, Stack, Img, Text, VStack, Collapse, SimpleGrid, useDisclosure, Heading, Grid} from "@chakra-ui/react";
import {toAbsoluteUrl} from "../../utils/helper";
import {useAuth} from "../../plugins/CookieAuthPlugin";
import {APP_VERSION} from "../../constants/app";
import {login} from "../../utils/apiClient/auth";
import {Redirect, useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {ChevronLeftIcon} from "@chakra-ui/icons";

export default function Login() {
  const {loginAuth, refetchUser, user} = useAuth();
  const {isOpen, onToggle} = useDisclosure();
  const history = useHistory();
  const [error, setError] = useState("");
  const recaptchaRef = useRef();
  const [logLoading, setLogLoading] = useState(false);
  if (Boolean(user)) {
    return <Redirect from="/login" to="/admin/promotion/list" />;
  }

  return (
    <SimpleGrid pos="relative" columns={{md: 2, sm: 1}} h="100vh">
      <Box d={{base: "none", md: "block"}} bgRepeat="no-repeat" bgSize="cover" bgPosition="center" bgImage={toAbsoluteUrl("/assets/login_bg.png")} />
      <VStack bgRepeat="no-repeat" overflow="hidden" pb={{base: "8rem", md: "6rem"}} justify="center" spacing="5rem">
        <Box position="relative" width="100%">
          <Button
            position="absolute"
            variant="outline"
            colorScheme="brand.500"
            color="brand.500"
            p={0}
            left={5}
            top={0}
            size="lg"
            onClick={() => {
              history.push("/home");
              window.location.reload();
            }}
          >
            <ChevronLeftIcon />
          </Button>
        </Box>
        <Img w={{base: "10rem", md: "13rem"}} mb="-30px" src={toAbsoluteUrl("/assets/Wingo Logo.png")} alt="" />
        <Grid>
          <Heading textAlign="center" as="h1">
            Welcome
          </Heading>
          <Box as="h4" color="brand.100">
            Welcome back, please log in your account with us
          </Box>
        </Grid>
        <Box zIndex="banner" w={{base: "80%", md: "60%"}}>
          <chakra.form
            onSubmit={async (e) => {
              e.preventDefault();
              const formData = new FormData(e.target);
              const formProps = Object.fromEntries(formData);
              setLogLoading(true);
              let res = await login({deviceId: 12, deviceName: 12, username: formProps.username, password: formProps.password});
              try {
                if (res?.header?.errorCode === "401" || res?.header?.statusCode === 401) {
                  recaptchaRef.current.reset();
                  setError(res?.header.errorText);
                }
                if (res.header.result) {
                  await loginAuth({token: res?.body?.accessToken});
                  res && refetchUser();
                  res && history.push("/admin/promotion");
                }
                if (!res.body.status) {
                  recaptchaRef.current.reset();
                  setError(res.body.message);
                }
              } catch (err) {
                setError(err.header?.errorCode === "401" ? "Something went wrong please try again" : "Incorrect username or password");
              }
              setLogLoading(false);
            }}
          >
            <Stack spacing="4">
              <FormControl id="username">
                <FormLabel color="gray.600" fontWeight="normal">
                  Username
                </FormLabel>
                <InputGroup>
                  <InputRightElement>
                    <IconButton color="brand.100" bg="transparent !important" icon={<HiUser />} />
                  </InputRightElement>
                  <Input bg="white" name="username" placeholder="Enter Username" autoComplete="text" required />
                </InputGroup>
              </FormControl>
              <FormControl id="password">
                <FormLabel color="gray.600" fontWeight="normal">
                  Password
                </FormLabel>
                <InputGroup>
                  <InputRightElement>
                    <IconButton tabIndex="-1" bg="transparent !important" variant="ghost" aria-label={isOpen ? "Mask password" : "Reveal password"} icon={isOpen ? <HiEye /> : <HiEyeOff />} onClick={onToggle} _focus={{outline: "none"}} colorScheme="brand.100" />
                  </InputRightElement>
                  <Input bg="white" name="password" type={isOpen ? "text" : "password"} autoComplete="current-password" placeholder="Enter Password" required />
                </InputGroup>
              </FormControl>
              {logLoading ? (
                ""
              ) : (
                <Collapse in={error} animateOpacity>
                  <Alert status="error">
                    <AlertIcon />
                    {error}
                  </Alert>
                </Collapse>
              )}
              <Button type="submit" isLoading={logLoading} spinner={<Loader type="ThreeDots" color="#fff" height={40} width={40} />} colorScheme="brand" size="md">
                LOGIN
              </Button>
            </Stack>
          </chakra.form>
        </Box>
        <Flex opacity="0.9" pos="fixed" bottom="5" fontSize="small" fontWeight="bold">
          Powered by&nbsp;<Text color="brand.500">UDAYA TECHNOLOGY Co., Ltd.</Text>
          &nbsp;
          <Text bg={"transparent"} color="transparent" _hover={{color: "gray.500"}}>
            v{APP_VERSION}
          </Text>
        </Flex>
      </VStack>
    </SimpleGrid>
  );
}
