import React from "react";
import { useMutation, useQuery } from "react-query";
import { SplashScreen } from "../components/Loader";
import { AUTH_USER } from "../constants/queryKeys";
import { queryClient } from "../libs/react-query";
import { getUserProfile, logout } from "../utils/apiClient/auth";
import { storage } from "../utils/helper";

const AuthContext = React.createContext(null);
AuthContext.displayName = "AuthContext";

async function handleTokenResponse(data) {
   let { token } = data;
   storage.setToken(token);
   return token;
}

async function loadUser() {
   let user = null;
   if (storage.getToken()) {
      user = await getUserProfile();
   }
   return user;
}

async function loginFn(data) {
   if (data) {
      return await handleTokenResponse(data);
   }
}

async function logoutFn() {
   const res = await logout();
   if (res) {
      storage.clearToken();
   }
}

export default function CookieAuth({ children }) {
   const key = AUTH_USER, waitInitial = true, LoaderComponent = () => <SplashScreen />,
      ErrorComponent = (error) => <div style={{ color: "tomato" }}>{JSON.stringify(error, null, 2)}</div>;

   const { data: user, error, status, isLoading, isIdle, isSuccess, refetch } = useQuery({
      queryKey: key, queryFn: loadUser,
   });

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const setUser = React.useCallback((data) => queryClient.setQueryData(key, data), [queryClient]);

   const loginMutation = useMutation({
      mutationFn: loginFn, onSuccess: (user) => {
         setUser(user);
      },
   });

   const logoutMutation = useMutation({
      mutationFn: logoutFn, onSuccess: () => {
         queryClient.clear();
      },
   });

   const value = React.useMemo(() => ({
      user,
      error,
      refetchUser: refetch,
      loginAuth: loginMutation.mutateAsync,
      isLoggingIn: loginMutation.isLoading,
      logout: logoutMutation.mutateAsync,
      isLoggingOut: logoutMutation.isLoading,
   }), [user, error, refetch, loginMutation.mutateAsync, loginMutation.isLoading, logoutMutation.mutateAsync, logoutMutation.isLoading,]);
   if (isSuccess || !waitInitial) {
      return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
   }

   if (isLoading || isIdle) {
      return <LoaderComponent />;
   }

   if (error) {
      return <ErrorComponent error={error} />;
   }

   return <div>Unhandled status: {status}</div>;
}

export function useAuth() {
   const context = React.useContext(AuthContext);
   if (!context) {
      throw new Error(`useAuth must be used within an Provider`);
   }
   return context;
}
