import React from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { APP_NAME, IS_APP_PRODUCTION } from "./constants/app";
import { useAuth } from "./plugins/CookieAuthPlugin";
import HomePageMobile from "./view/HomePageMobile";
import PreviousResultsMobile from "./view/PreviousResultsMobile";
import PrivacyPolicies from "./view/PrivacyPolicies";
import BaseAdminPage from "./view/app";
import Login from "./view/auth";
import BaseSitePage from "./view/webpage";

export default function Routes() {
  const { user } = useAuth();
  return (
    <Router basename={IS_APP_PRODUCTION ? "" : APP_NAME}>
      <Switch>
        <Route exact path="/login" render={() => (user ? <Redirect to="/admin/promotion/list" /> : <Login />)} />
        <PrivateRoute path="/admin" component={BaseAdminPage} isAuthenticated={Boolean(user)} />
        <Route path="/privacy-policy" component={PrivacyPolicies} />
        <Route path="/previous-result-mobile" component={PreviousResultsMobile} />
        <Route path="/home-mobile" component={HomePageMobile} />
        <Route path="/" component={BaseSitePage} />
      </Switch>
    </Router>
  );
}

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
