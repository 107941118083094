import {Box, ListItem, VStack, OrderedList, UnorderedList, Image} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {toAbsoluteUrl} from "../../../utils/helper";

const AboutCompany = () => {
  const {t} = useTranslation();
  return (
    <VStack mb="10px">
      <Content title={t("aboutcompany.about.header")}>
        <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("aboutcompany.about.title1")}</Box>
        <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("aboutcompany.about.title2")}</Box>
        <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("aboutcompany.about.title3")}</Box>
      </Content>
      <Content id="about-vision" title={t("aboutcompany.vision.header")}>
        <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("aboutcompany.vision.content")}</Box>
      </Content>
      <Content id="about-mission" title={t("aboutcompany.mission.header")}>
        <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>
          {t("aboutcompany.mission.content1")}
          <span>{t("aboutcompany.mission.content2")}</span>
          {t("aboutcompany.mission.content3")}
        </Box>
      </Content>
      <Content id="about-values" title={t("aboutcompany.advantage.header")}>
        <Box>{t("aboutcompany.advantage.option1")}</Box>
        <Box>{t("aboutcompany.advantage.option2")}</Box>
        <Box>{t("aboutcompany.advantage.option3")}</Box>
        <Box>{t("aboutcompany.advantage.option4")}</Box>
        <Box>{t("aboutcompany.advantage.option5")}</Box>
        <Box>{t("aboutcompany.advantage.option6")}</Box>
        <Image mt={{base: "10px", md: "20px", lg: "30px"}} align="center" src={toAbsoluteUrl("/assets/about-company/CoreValue.jpg")} />
      </Content>
      <Content id="about-cultures" title={t("aboutcompany.culture.header")}>
        <OrderedList>
          <ListItem id="line1">
            <Box>{t("aboutcompany.culture.integrity.title")}</Box>
            <Box>{t("aboutcompany.culture.integrity.option1")}</Box>
            <Box>{t("aboutcompany.culture.integrity.option2")}</Box>
            <Box>{t("aboutcompany.culture.integrity.option3")}</Box>
          </ListItem>
          <ListItem id="line2">
            <Box>{t("aboutcompany.culture.accountability.title")}</Box>
            <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("aboutcompany.culture.accountability.content")}</Box>
          </ListItem>
          <ListItem id="line3">
            <Box>{t("aboutcompany.culture.service.title")}</Box>
            <Box>{t("aboutcompany.culture.service.option1")}</Box>
            <Box>{t("aboutcompany.culture.service.option2")}</Box>
            <Box>{t("aboutcompany.culture.service.option3")}</Box>
            <Box>{t("aboutcompany.culture.service.option4")}</Box>
            <Box>{t("aboutcompany.culture.service.option5")}</Box>
            <Box>{t("aboutcompany.culture.service.option6")}</Box>
            <Box>{t("aboutcompany.culture.service.option7")}</Box>
            <Box>{t("aboutcompany.culture.service.option8")}</Box>
            <Box>{t("aboutcompany.culture.service.option9")}</Box>
            <Box>{t("aboutcompany.culture.service.option10")}</Box>
          </ListItem>
          <ListItem id="line4">
            <Box>{t("aboutcompany.culture.professional.title")}</Box>
            <Box>{t("aboutcompany.culture.professional.option1")}</Box>
            <Box>{t("aboutcompany.culture.professional.option2")}</Box>
            <Box>{t("aboutcompany.culture.professional.option3")}</Box>
            <Box>{t("aboutcompany.culture.professional.option4")}</Box>
            <Box>{t("aboutcompany.culture.professional.option5")}</Box>
            <Box>{t("aboutcompany.culture.professional.option6")}</Box>
            <Box>{t("aboutcompany.culture.professional.option7")}</Box>
          </ListItem>
          <ListItem id="line5">
            <Box>{t("aboutcompany.culture.right_thing.title")}</Box>
            <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("aboutcompany.culture.right_thing.content")}</Box>
          </ListItem>
          <ListItem id="line6">
            <Box>{t("aboutcompany.culture.focus.title")}</Box>
            <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("aboutcompany.culture.focus.content")}</Box>
          </ListItem>
          <ListItem id="line7">
            <Box>{t("aboutcompany.culture.learning.title")}</Box>
            <Box>{t("aboutcompany.culture.learning.option1")}</Box>
            <Box>{t("aboutcompany.culture.learning.option2")}</Box>
            <Box>{t("aboutcompany.culture.learning.option3")}</Box>
            <Box>{t("aboutcompany.culture.learning.option4")}</Box>
          </ListItem>
          <ListItem id="line8">
            <Box>{t("aboutcompany.culture.believe.title")}</Box>
            <Box>{t("aboutcompany.culture.believe.option1")}</Box>
            <Box>{t("aboutcompany.culture.believe.option2")}</Box>
          </ListItem>
          <ListItem id="line9">
            <Box>{t("aboutcompany.culture.teamwork.title")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option1")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option2")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option3")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option4")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option5")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option6")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option7")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option8")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option9")}</Box>
            <Box>{t("aboutcompany.culture.teamwork.option10")}</Box>
          </ListItem>
          <ListItem id="line10">
            <Box>{t("aboutcompany.culture.gratitude.title")}</Box>
            <Box sx={{textIndent: {base: "30px", md: "40px", lg: "50px"}}}>{t("aboutcompany.culture.gratitude.content")}</Box>
            <UnorderedList ml={{base: "5", sm: "7"}}>
              <ListItem>{t("aboutcompany.culture.gratitude.option1")}</ListItem>
              <ListItem>{t("aboutcompany.culture.gratitude.option2")}</ListItem>
              <ListItem>{t("aboutcompany.culture.gratitude.option3")}</ListItem>
            </UnorderedList>
          </ListItem>
        </OrderedList>
      </Content>
      <Content title={t("aboutcompany.manager.title")}>
        <Box>{t("aboutcompany.manager.option1")}</Box>
        <Box>{t("aboutcompany.manager.option2")}</Box>
        <Box>{t("aboutcompany.manager.option3")}</Box>
      </Content>
    </VStack>
  );
};
const Content = ({title, children}) => {
  return (
    <Box width="100%">
      <Box fontSize={{base: "20px", md: "24px", lg: "28px"}} borderBottom="solid 1px #A9411B" borderTop="solid 1px #A9411B" fontFamily={"Baphnom"} textAlign={"center"} lineHeight={{base: "60px", md: "70px", lg: "80px"}} width="95%" mx={"auto"} my={{base: "20px", md: "30px", lg: "40px"}}>
        {title}
      </Box>
      <Box className="about-content" fontSize={{base: "14px", md: "16px", lg: "18px"}} fontFamily={"Metal Chrieng"} lineHeight={{base: "34px", md: "38px", lg: "42px"}} width="95%" mx={"auto"}>
        {children}
      </Box>
    </Box>
  );
};

export default AboutCompany;
