import {useState, useEffect} from "react";
import TableLiveResult from "../components/Tables/TableLiveResult";
import {useGetTargetDate} from "../utils/hooks/live-table";
import CountdownTimer from "../components/CountdownTimer";
import {Box, Container, Flex, Image, VStack} from "@chakra-ui/react";
import {toAbsoluteUrl} from "../utils/helper";
import NavBottom from "../components/navBottom";
const staticTesting = false;
const time = "2024-03-28 09:43:00";
export default function HomePageMobile() {
  const [timeLiveTable, setTimeLiveTable] = useState("");
  const getAllDateTime = useGetTargetDate();
  const [isOnTime, setOnTime] = useState(false);

  useEffect(() => {
    // fetch date
    if (getAllDateTime.data && getAllDateTime.isSuccess) {
      const data = getAllDateTime.data;
      setTimeLiveTable(data.timeLiveTable);
    }
  }, [getAllDateTime]);

  useEffect(() => {
    let timeoutId = null;
    timeoutId = setInterval(() => {
      getAllDateTime.refetch();
    }, 300000); // refetch data in every 5mn
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, []);
  const handleOnTime = (data) => setOnTime(data);

  return (
    <Box bg="#EDDBCD" backgroundImage={toAbsoluteUrl("/assets/bg_patterns.svg")} width="100%" id="no-print">
      <Flex bg="#A9411B" pb={{base: 1, md: 3, lg: 5}} mb={{base: 5, md: 7, lg: 10}}>
        <Container maxW="1440px" align="center">
          <Image id="romduol-banner" src={toAbsoluteUrl("/assets/romduol-banner.png")} alt="romduol-banner" width="80%" />
        </Container>
      </Flex>
      <Box maxWidth="1440px" mx="auto" minHeight="100vh">
        <VStack mx={{ base: "10px" }} mb="15px">
          <Box width="100%">
            {timeLiveTable && <CountdownTimer timeShow={staticTesting ? time : timeLiveTable} isOnTime={handleOnTime} isLoading={getAllDateTime.isLoading} handleOnTime={handleOnTime} />}
            <TableLiveResult timeShow={staticTesting ? time : timeLiveTable} staticTesting={staticTesting} isOnTime={isOnTime} />
          </Box>
        </VStack>
      </Box>
      <NavBottom />
    </Box>
  );
}
