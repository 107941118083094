import {extendTheme} from "@chakra-ui/react";
// File font source default is 400
// So we want to use it as we link on file index.html we need to config it inside node module
// nodeModule -> @fontsource -> inter or poppins -> index.css (config there)
import "@fontsource-variable/noto-sans-khmer";
import "@fontsource/content";
import "@fontsource/inter";
import "@fontsource/kantumruy-pro";
import "@fontsource/khmer";
import "@fontsource/moul";
import "@fontsource/poppins";
import "./App.css";

export const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  colors: {
    greenAlt: {
      50: "#1b5f38",
      100: "#1b5f38",
      200: "#1b5f38",
      300: "#1b5f38",
      400: "#1b5f38",
      500: "#1b5f38",
      600: "#1b5f38",
      700: "#1b5f38",
      800: "#1b5f38",
      900: "#1b5f38",
    },
    brand: {
      50: "#ff5b5b",
      100: "#ff5b5b",
      200: "#ff5b5b",
      300: "#ff5b5b",
      400: "#ff5b5b",
      500: "#ff5b5b",
      600: "#e65252",
      700: "#bf4444",
      800: "#993737",
      900: "#7d2d2d",
    },
  },
  styles: {
    global: {
      "*": {
        "user-select": "none",
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#a8a8a8",
          borderRadius: "24px",
          transition: "width 0.2s, background-color 0.2s",
          "&:hover": {
            width: "8px", // Increase width on hover
            background: "#888", // Change background on hover
          },
        },
        "&::-webkit-scrollbar-track": {
          width: "8px",
          height: "4px",
          background: "transparent",
        },
        b: {
          color: "red",
        },
        scrollbarWidth: "thick",
      },
      ".gm-ui-hover-effect": {
        display: "none !important",
      },

      ".infoBox": {
        overflow: "initial !important",
        zIndex: "-1000 !important",
      },
      ".infoBox img": {
        display: "none !important",
      },
      ".gm-control-active": {
        zIndex: "var(--chakra-zIndices-docked)",
      },
      ".gmnoprint:last-child": {
        float: "right",
      },
    },
  },

  fontSizes: {
    sm: "0.83rem",
    md: "0.9rem",
  },
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Noto Sans Khmer Variable, sans- serif",
    kh: "Khmer, sans-serif",
    content: "Content, sans-serif",
    moul: "Moul Light, sans-serif",
    baphnom: "baphnom, sans-serif",
    tarpom: "Taprom, sans-serif",
    industry: "industry, sans-serif",
    georgiaproblack: "GeorgiaPro-Black, sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        // fontWeight: "medium",
      },
      variants: {
        duotone: (props) => ({
          bg: `${props.colorScheme}.100`,
          color: `${props.colorScheme}.600`,
          border: `1px solid`,
          px: "var(--chakra-space-3)",
          borderColor: `${props.colorScheme}.500`,
          _hover: {bg: `${props.colorScheme}.200`},
        }),
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: "brand.400",
      },
      variants: {
        outline: {
          field: {
            _disabled: {
              opacity: 1,
              _hover: {
                borderColor: "inherit",
                cursor: "default",
              },
            },
          },
        },
      },
    },
    CheckBox: {
      variants: {
        bg: "brand.100",
      },
    },
    NumberInput: {
      defaultProps: {
        focusBorderColor: "brand.400",
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: "brand.400",
      },
      variants: {
        outline: {
          _disabled: {
            opacity: 1,
            _hover: {
              borderColor: "inherit",
              cursor: "default",
            },
          },
        },
      },
    },
  },
});
