
export const typeOfContact = [{ value: 1, label: "Text" }, { value: 2, label: "Telephone" }, {
   value: 3,
   label: "Email"
}, { value: 4, label: "Website" },];

export const acceptFile = {
   image: "image/*", excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", pdf: ".pdf",
};

export const dataObject2 = [
   {
      "id": 1,
      "winningName": "លួងចិត្ត 2D",
      "winningLevel": 12,
      "winningPrice": 10000,
      "totalSwingTime": 2,
      "digitNumber": 2,
      "winningResult": ["40", "20"]
   },
   {
      "id": 2,
      "winningName": "លួងចិត្ត 2D",
      "winningLevel": 11,
      "winningPrice": 20000,
      "totalSwingTime": 1,
      "digitNumber": 2,
      "winningResult": ["16"]
   },
   {
      "id": 3,
      "winningName": "លួងចិត្ត 3D",
      "winningLevel": 10,
      "winningPrice": 50000,
      "totalSwingTime": 2,
      "digitNumber": 3,
      "winningResult": ["914", "752"]
   },
   {
      "id": 4,
      "winningName": "លួងចិត្ត 4D",
      "winningLevel": 9,
      "winningPrice": 100000,
      "totalSwingTime": 10,
      "digitNumber": 4,
      "winningResult": ["9367", "2591", "4136", "7591", "5673", "7195", "2376", "9751", "6327", "4159"]
   },
   {
      "id": 5,
      "winningName": "លួងចិត្ត 4D",
      "winningLevel": 8,
      "winningPrice": 200000,
      "totalSwingTime": 2,
      "digitNumber": 4,
      "winningResult": ["6419", "3746"]
   },
   {
      "id": 6,
      "winningName": "លួងចិត្ត 4D",
      "winningLevel": 7,
      "winningPrice": 500000,
      "totalSwingTime": 1,
      "digitNumber": 4,
      "winningResult": ["2584"]
   },
   {
      "id": 7,
      "winningName": "លួងចិត្ត 5D",
      "winningLevel": 6,
      "winningPrice": 1000000,
      "totalSwingTime": 6,
      "digitNumber": 5,
      "winningResult": ["72184", "63517", "31879", "52973", "42681", "93754"]
   },
   {
      "id": 8,
      "winningName": "លួងចិត្ត 5D",
      "winningLevel": 5,
      "winningPrice": 2000000,
      "totalSwingTime": 3,
      "digitNumber": 5,
      "winningResult": ["49673", "81257", "20346", "90578"]
   },
   {
      "id": 9,
      "winningName": "លួងចិត្ត 5D",
      "winningLevel": 4,
      "winningPrice": 5000000,
      "totalSwingTime": 1,
      "digitNumber": 5,
      "winningResult": ["57842"]
   },
   {
      "id": 10,
      "winningName": "លួងចិត្ត សំរិទ្ធ",
      "winningLevel": 3,
      "winningPrice": 10000000,
      "totalSwingTime": 4,
      "digitNumber": 6,
      "winningResult": ["158243", "729531", "259386", "614297"]
   },
   {
      "id": 11,
      "winningName": "លួងចិត្ត ប្រាក់",
      "winningLevel": 2,
      "winningPrice": 20000000,
      "totalSwingTime": 3,
      "digitNumber": 6,
      "winningResult": ["759842", "257836", "417926"]
   },
   {
      "id": 12,
      "winningName": "លួងចិត្ត មាស",
      "winningLevel": 1,
      "winningPrice": 100000000,
      "totalSwingTime": 1,
      "digitNumber": 6,
      "winningResult": ["524372"]
   }
]

export const dataObject = [
   {
      "id": 1,
      "winningName": "លួងចិត្ត 2D",
      "winningLevel": 12,
      "winningPrice": 10000,
      "totalSwingTime": 2,
      "digitNumber": 2,
      "winningResult": ["39", "84"]
   },
   {
      "id": 2,
      "winningName": "លួងចិត្ត 2D",
      "winningLevel": 11,
      "winningPrice": 20000,
      "totalSwingTime": 1,
      "digitNumber": 2,
      "winningResult": ["16"]
   },
   {
      "id": 3,
      "winningName": "លួងចិត្ត 3D",
      "winningLevel": 10,
      "winningPrice": 50000,
      "totalSwingTime": 2,
      "digitNumber": 3,
      "winningResult": ["914", "752"]
   },
   {
      "id": 4,
      "winningName": "លួងចិត្ត 4D",
      "winningLevel": 9,
      "winningPrice": 100000,
      "totalSwingTime": 10,
      "digitNumber": 4,
      "winningResult": ["9367", "2591", "4136", "7591", "5673", "7195", "2376", "9751", "6327", "4159"]
   },
   {
      "id": 5,
      "winningName": "លួងចិត្ត 4D",
      "winningLevel": 8,
      "winningPrice": 200000,
      "totalSwingTime": 2,
      "digitNumber": 4,
      "winningResult": ["6419", "3746"]
   },
   {
      "id": 6,
      "winningName": "លួងចិត្ត 4D",
      "winningLevel": 7,
      "winningPrice": 500000,
      "totalSwingTime": 1,
      "digitNumber": 4,
      "winningResult": ["2584"]
   },
   {
      "id": 7,
      "winningName": "លួងចិត្ត 5D",
      "winningLevel": 6,
      "winningPrice": 1000000,
      "totalSwingTime": 6,
      "digitNumber": 5,
      "winningResult": ["72184", "63517", "31879", "52973", "42681", "93754"]
   },
   {
      "id": 8,
      "winningName": "លួងចិត្ត 5D",
      "winningLevel": 5,
      "winningPrice": 2000000,
      "totalSwingTime": 3,
      "digitNumber": 5,
      "winningResult": ["49673", "81257", "20346", "90578"]
   },
   {
      "id": 9,
      "winningName": "លួងចិត្ត 5D",
      "winningLevel": 4,
      "winningPrice": 5000000,
      "totalSwingTime": 1,
      "digitNumber": 5,
      "winningResult": ["57842"]
   },
   {
      "id": 10,
      "winningName": "លួងចិត្ត សំរិទ្ធ",
      "winningLevel": 3,
      "winningPrice": 10000000,
      "totalSwingTime": 4,
      "digitNumber": 6,
      "winningResult": ["158243", "729531", "259386", "614297"]
   },
   {
      "id": 11,
      "winningName": "លួងចិត្ត ប្រាក់",
      "winningLevel": 2,
      "winningPrice": 20000000,
      "totalSwingTime": 3,
      "digitNumber": 6,
      "winningResult": ["759842", "257836", "417926"]
   },
   {
      "id": 12,
      "winningName": "លួងចិត្ត មាស",
      "winningLevel": 1,
      "winningPrice": 100000000,
      "totalSwingTime": 1,
      "digitNumber": 6,
      "winningResult": ["824389"]
   }
]

 export const TableData = (t) => [
    {
       "id": "p12",
       "winningName": t("table.reward2D"),
       "winningLevel": 12,
       "winningPrice": 10000,
       "totalSwingTime": 2,
       "digitNumber": 2,

    },
    {
       "id": "p11",
       "winningName": t("table.reward2D"),
       "winningLevel": 11,
       "winningPrice": 20000,
       "totalSwingTime": 1,
       "digitNumber": 2,
    },
    {
       "id": "p10",
       "winningName": t("table.reward3D"),
       "winningLevel": 10,
       "winningPrice": 50000,
       "totalSwingTime": 2,
       "digitNumber": 3,
    },
    {
       "id": "p9",
       "winningName": t("table.reward4D"),
       "winningLevel": 9,
       "winningPrice": 100000,
       "totalSwingTime": 10,
       "digitNumber": 4,
    },
    {
       "id": "p8",
       "winningName": t("table.reward4D"),
       "winningLevel": 8,
       "winningPrice": 200000,
       "totalSwingTime": 2,
       "digitNumber": 4,
    },
    {
       "id": "p7",
       "winningName": t("table.reward4D"),
       "winningLevel": 7,
       "winningPrice": 500000,
       "totalSwingTime": 1,
       "digitNumber": 4,
    },
    {
       "id": "p6",
       "winningName": t("table.reward5D"),
       "winningLevel": 6,
       "winningPrice": 1000000,
       "totalSwingTime": 6,
       "digitNumber": 5,
    },
    {
       "id": "p5",
       "winningName": t("table.reward5D"),
       "winningLevel": 5,
       "winningPrice": 2000000,
       "totalSwingTime": 3,
       "digitNumber": 5,
    },
    {
       "id": "p4",
       "winningName": t("table.reward5D"),
       "winningLevel": 4,
       "winningPrice": 5000000,
       "totalSwingTime": 1,
       "digitNumber": 5,
    },
    {
       "id": "p3",
       "winningName": t("table.rewardBronze"),
       "winningLevel": 3,
       "winningPrice": 10000000,
       "totalSwingTime": 4,
       "digitNumber": 6,
    },
    {
       "id": "p2",
       "winningName": t("table.rewardSilver"),
       "winningLevel": 2,
       "winningPrice": 20000000,
       "totalSwingTime": 3,
       "digitNumber": 6,
    },
    {
       "id": "p1",
       "winningName": t("table.rewardGold"),
       "winningLevel": 1,
       "winningPrice": 100000000,
       "totalSwingTime": 1,
       "digitNumber": 6,
    }
 ]

